<div class="d-flex">
  <div class="flex-grow-1">
    <nemo-teacher-search-input (addTeacher)="addTeacher.emit($event)"></nemo-teacher-search-input>
  </div>
  <div class="ms-2" *ngIf="deleteEnabled">
    <button type="button" class="btn btn-outline-secondary" tooltip="Enlever tous les enseignants"
      tooltipPlacement="top" (click)="dropAll()"><span class="bi bi-person-fill-x"></span> Enlever tous les
      enseignants</button>
  </div>
  <div class="ms-2"><ng-content></ng-content></div>
</div>
