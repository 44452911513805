import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserRight } from '@app/model';
import { UserDisplayComponent } from '../user-display/user-display.component';
import { TeacherSearchComponent } from '../teacher-search/teacher-search.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'nemo-educational-team-edit',
  standalone: true,
  imports: [CommonModule, UserDisplayComponent, TeacherSearchComponent,FormsModule],
  templateUrl: './educational-team-edit.component.html',
  styleUrl: './educational-team-edit.component.scss'
})
export class EducationalTeamEditComponent {
  @Input() educationalTeam: UserRight[];
  @Output() educationalTeamChange = new EventEmitter<UserRight[]>();

  send():void{
    this.educationalTeamChange.emit(this.educationalTeam);
  }
  
  addTeacher(teacher: UserRight) {
    if (this.educationalTeam.filter(s => s.objectGuid === teacher.objectGuid).length === 0) {
      this.educationalTeam.push(new UserRight({
        displayName: teacher.displayName,
        objectGuid: teacher.objectGuid,
        canEdit: true
      }));
      this.educationalTeam.sort((a, b) => a.displayName.localeCompare(b.displayName));
      this.educationalTeamChange.emit(this.educationalTeam);
    }
  }
  sortTeachers() {
    this.educationalTeam.sort((a, b) => a.displayName.localeCompare(b.displayName));
  }
  dropTeachers() {
    this.educationalTeam = new Array<UserRight>();
    this.educationalTeamChange.emit(this.educationalTeam);
  }
  dropTeacher(teacher: UserRight) {
    this.educationalTeam.splice(this.educationalTeam.indexOf(teacher), 1);
    this.educationalTeamChange.emit(this.educationalTeam);
  }

}
