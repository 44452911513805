import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ListeService, ToastService, ReferenceDataService, InputValidationService, PreferencesService } from '@app/core/service';
import { Item, ItemList, Language, Logo, UserRight } from '@app/model';
import { ImportFromTextModalComponent } from '@app/shared/components/import-from-text-modal/import-from-text-modal.component';
import { SuppressModalComponent } from '@app/shared/components/suppress-modal/suppress-modal.component';
import { NgbModal, NgbCollapse, NgbDropdownButtonItem, NgbDropdownItem, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaService, DragulaModule } from 'ng2-dragula';
import { CanComponentDeactivate } from '@app/core/guards/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { DialogService } from '@app/core/service/dialog.service';
import { ListeItemComponent } from '../liste-item/liste-item.component';
import { MediaUploadModalComponent } from '@app/shared/components/media-upload-modal/media-upload-modal.component';
import { ConjugationSelectorModalComponent } from '@app/shared/components/conjugation-selector-modal/conjugation-selector-modal.component';
import { NgIf, NgFor } from '@angular/common';
import { SettingsService } from '@app/core/service/settings.service';
import { SharedModule } from '@app/shared/shared.module';

@Component({
    selector: 'nemo-liste-modification',
    templateUrl: './liste-modification.component.html',
    styleUrls: ['./liste-modification.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgbCollapse, RouterLink, NgFor, NgbDropdownButtonItem, NgbDropdownItem, DragulaModule, ListeItemComponent,NgbNavModule,SharedModule]
})
export class ListeModificationComponent implements OnInit, OnDestroy, CanComponentDeactivate, AfterViewInit {
  @ViewChild('bottomElem', { static: false }) BottomElement: ElementRef;
  @HostListener('window:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        this.submitForm();
        event.preventDefault();
    }
  }

  public list: ItemList;
  public availableLanguages: Language[];
  public userForm: FormGroup;
  public typelabel: string;
  public closeResult: string;
  public publisher = false;
  public teacher = false;
  public isCollapsed = true;
  public isLangCollapsed = true;
  @ViewChildren('formRow') rows: QueryList<ElementRef>;
  focusedItemIndex = 0;

  constructor(
    public router: Router,
    private readonly listeservice: ListeService,
    private readonly referenceDataService: ReferenceDataService,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly dragulaService: DragulaService,
    private readonly toastService: ToastService,
    public preferencesService: PreferencesService,
    private readonly modalService: NgbModal,
    public inputValidationService: InputValidationService,
    private readonly dialogService: DialogService,
    public readonly settings: SettingsService
  ) {
    this.dragulaService.drag('dnditems').subscribe(({el, source}) => {
      this.userForm.markAsDirty();
    });
  }
  canDeactivate(): Observable<boolean> | boolean {
    // Allow synchronous navigation (`true`) if no crisis or the crisis is unchanged
    if (!this.userForm.dirty) {
      return true;
    }
    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    return this.dialogService.confirm('Vous n\'avez pas sauvegardé les modifications, souhaitez-vous continuer sans sauvegarder ?');
  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.listeservice.getList(params['id']).subscribe(list => {
        this.list = list;
        if(!list.canEdit){
          this.router.navigate(['/']);
        }
        this.focusedItemIndex = list.items.length - 1;
        this.dragulaService.createGroup('dnditems', {
          moves: function (el, source, handle, sibling) {
            return handle.className.indexOf('bi-three-dots-vertical') >= 0;
          }
        });
        this.availableLanguages = this.referenceDataService.getLanguages();
        this.initForm();
      });
    });
    this.publisher = this.preferencesService.isPublisher();
    this.teacher =   this.preferencesService.isTeacher();
  }

  ngAfterViewInit() {
    this.rows.changes.subscribe((r) => {
      this.setFocusOnItem(this.focusedItemIndex + 1);
    });
  }

  ngOnDestroy() {
    if (this.dragulaService.find('dnditems') !== undefined) {
      this.dragulaService.destroy('dnditems');
    }
  }

  handleTranslation(e: boolean) {
    if (e) {
      this.userForm.controls['hintLanguage'].setValue(
        this.availableLanguages.find(l => l.id === this.userForm.get('learnLanguage').value.id)
      );
      this.userForm.controls['learnLanguage'].setValue(
        this.availableLanguages.find(l => l.id !== this.userForm.get('learnLanguage').value.id)
      );
    } else {
      this.userForm.controls['learnLanguage'].setValue(
        this.availableLanguages.find(l => l.id === this.userForm.get('hintLanguage').value.id)
      );
    }
    this.userForm.markAsDirty();
  }

  dropLearns() {
    this.list.items.forEach((item, index) => {
      item.learnTxt = '';
    });
    this.userForm.markAsDirty();
  }

  dropHints() {
    this.list.items.forEach((item, index) => {
      item.hintTxt = '';
    });
    this.userForm.markAsDirty();
  }

  dropImgs() {
    this.list.items.forEach((item, index) => {
      item.hintImgUrl = null;
    });
    this.userForm.markAsDirty();
  }
  dropAll() {
    const modalRef = this.modalService.open(SuppressModalComponent);
    modalRef.componentInstance.title = 'Attention';
    modalRef.componentInstance.message = 'Voulez-vous supprimer tous les éléments de cette liste ?' ;
    modalRef.result.then((result) => {
        this.list.items = [];
        this.userForm.markAsDirty();
    },()=>{});
  }

  initForm() {
    this.userForm = this.formBuilder.group({
      title: [this.list.title, [Validators.required]],
      description: [this.list.description],
      learnLanguage: [this.availableLanguages.find(l => l.id === this.list.learnLanguage)],
      hintLanguage: [this.availableLanguages.find(l => l.id === this.list.hintLanguage)],
      ribbon: [this.list.ribbon, [Validators.maxLength(10)]]
    });
  }

  submitForm(): void {
    this.list.title = this.inputValidationService.cleanItem(this.userForm.get('title').value);
    this.list.description = this.inputValidationService.cleanItem(this.userForm.get('description').value);
    this.list.items.forEach(i => {
      i.hintTxt = this.inputValidationService.cleanItem(i.hintTxt);
      i.learnTxt = this.inputValidationService.cleanItem(i.learnTxt);
    });
    this.list.learnLanguage = this.userForm.get('learnLanguage').value
      ? this.userForm.get('learnLanguage').value.id
      : null;
    this.list.hintLanguage = this.userForm.get('hintLanguage').value
      ? this.userForm.get('hintLanguage').value.id
      : null;
    this.listeservice.saveList(this.list).subscribe(list => {
      this.toastService.success('Liste sauvegardée');
      this.userForm.markAsPristine();
    });
  }

  updateLogo(logo: Logo) {
    this.list.color = logo.color;
    this.list.icon = logo.icon;
    this.userForm.markAsDirty();
  }
  updateClassification(cat: string[]) {
    this.list.categories = cat;
    this.userForm.markAsDirty();
  }

  importFromText() {
    const modalRef = this.modalService.open(ImportFromTextModalComponent, {
      size: 'lg'
    });
    modalRef.result.then(
      result => {
        result.txt.split('\n').forEach(line => {
          if (line.split('\t').length <= 11) {
            const elems = line.split('\t');
            this.list.items.push(
              new Item({
                id: -1,
                learnTxt: elems.length > 0 ? elems[0] : '',
                hintTxt: elems.length > 1 ? elems[1] : '',
                hintImgUrl: elems.length > 2 ? elems[2] : '',
                tags: elems.length > 3 ? elems.slice(3) : []
              })
            );
          }
        });
        this.userForm.markAsDirty();
      },
      () => { }
    );
  }
  get conjugaisonEnabled(): boolean {
    return (this.list.learnLanguage === this.list.hintLanguage || this.list.hintLanguage === 'fr') && this.settings.feature_flags.conjugaison;
  }
  generateConjugaisonItems() {
    const modalRef = this.modalService.open(ConjugationSelectorModalComponent, {
      size: 'xl'
    });
    modalRef.componentInstance.learnLanguageCode = this.list.learnLanguage;
    modalRef.componentInstance.useHintInFrench = this.list.hintLanguage === 'fr';
    modalRef.result.then(
      result => {
        result.items.map(u => {
        this.list.items.push({
          id:-1,
          hintTxt:  result.useFrenchHints ? u.hintTxtInFrench : u.hintTxt,
          tags: u.tags,
          learnTxt: u.learnTxt
        });
        this.userForm.markAsDirty();
      })},
      () => { }
    );
  }

  deleteList() {
    const modalRef = this.modalService.open(SuppressModalComponent);
    modalRef.result.then(
      result => {
        this.listeservice.deleteList(this.list.id).subscribe(res => {
          this.router.navigate(['../../', { relativeTo: this.route}]);
        });
      },
      () => { }
    );
  }

  deleteItem(item: Item) {
    this.list.items.splice(this.list.items.indexOf(item), 1);
    this.userForm.markAsDirty();
  }

  moveItemUp(item: Item) {
    this.move(this.list.items, item, -1);
    this.userForm.markAsDirty();
  }

  moveItemDown(item: Item) {
    this.move(this.list.items, item, 1);
    this.userForm.markAsDirty();
  }

  addItem() {
    this.list.items.splice(this.focusedItemIndex + 1, 0, {
        id: -1,
        learnTxt: '',
        hintTxt: ''
      });
    this.BottomElement.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });

    this.userForm.markAsDirty();
  }

  getLanguageDescription(language: any): string{
    if(language.id === 'nc') {
      return 'dans une autre langue';
    }
    return `en ${language.label}`;
  }

  setFocusOnItem(idx: number) {
    const el = this.rows.length > 0 && this.rows.length >= idx ?
      this.rows.filter((element, index) => index === idx)[0] as unknown as ListeItemComponent :
      null;
    if (el) {
      el.setFocus();
    }
  }

  setCurrent(idx: number) {
    this.focusedItemIndex = idx;
  }

  itemUpdated() {
    this.userForm.markAsDirty();
  }
  translate() {
    this.list.items.forEach((item, index) => {
      if ((item.hintTxt === '' || item.hintTxt === null || !item.hintTxt) && item.learnTxt !== '') {
        item.hintTxt = this.listeservice.translateText(
          item.learnTxt,
          this.userForm.get('learnLanguage').value,
          this.userForm.get('hintLanguage').value
        );
      } else if ((item.learnTxt === '' || item.learnTxt === null || !item.learnTxt) && item.hintTxt !== '') {
        item.learnTxt = this.listeservice.translateText(
          item.hintTxt,
          this.userForm.get('hintLanguage').value,
          this.userForm.get('learnLanguage').value
        );
      }
    });
    this.userForm.markAsDirty();
  }
  switch() {
    this.list.items.forEach((item, index) => {
      const tmpo = item.hintTxt;
      item.hintTxt = item.learnTxt;
      item.learnTxt = tmpo;
    });
    const tmp = this.userForm.get('learnLanguage').value;
    this.userForm.controls['learnLanguage'].setValue(this.userForm.get('hintLanguage').value);
    this.userForm.controls['hintLanguage'].setValue(tmp);

    this.userForm.markAsDirty();
  }
  openUploadImg(item: Item) {
    const modalRef = this.modalService.open(MediaUploadModalComponent, {backdrop: 'static' , size: 'lg'});
    modalRef.componentInstance.mediaUrl = item.hintImgUrl;
    modalRef.componentInstance.onlyImages = true;
    modalRef.result.then(
      result => {
        item.hintImgUrl = result.url;
        this.userForm.markAsDirty();
      },
      () => { }
    );
  }
  private move(array, element, delta) {
    const index = array.indexOf(element);
    const newIndex = index + delta;
    if (newIndex < 0 || newIndex === array.length) {
      return;
    } // Already at the top or bottom.
    const indexes = [index, newIndex].sort(); // Sort the indixes
    array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); // Replace from lowest index, two elements, reverting the order
  }
  educationalTeamChanged(){
    this.userForm.markAsDirty();
  }

}
