export class User {

  displayName: string;
  objectGuid: string;
  

  constructor(model?: User) {
    if (model) {
      this.displayName = model.displayName;
      this.objectGuid = model.objectGuid;
    }
  }
}
