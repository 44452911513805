<div class="sticked-top">
  <div class="card my-2">
    <div class="card-body">
      <div class="d-flex">
        <div class="flex-grow-1 d-grid gap-2">
          <nemo-teacher-search (addTeacher)="addTeacher($event)" (dropTeachers)="dropTeachers()">
            <ng-content></ng-content>
          </nemo-teacher-search>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngFor="let teacher of educationalTeam">
  <nemo-user-display (dropUser)="dropTeacher(teacher)">
    <div class="d-flex">
      <div class="p-2">{{teacher.displayName}}</div>
      <div class="p-2">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" [(ngModel)]="teacher.canEdit" [ngModelOptions]="{standalone: true}" (change)="send()">
          <label class="form-check-label" for="flexSwitchCheckDefault"><i class="bi bi-pencil-square"></i> Peut modifier</label>
        </div>
      </div>
    </div>
  </nemo-user-display>
</div>