import { Component, ElementRef, ViewChild } from '@angular/core';
import { SharedModule } from "../../../shared/shared.module";
import { BookletService, PlanEtudeRomandService, ToastService } from '@app/core/service';
import { CommonModule } from '@angular/common';
import * as saveAs from 'file-saver';
import { catchError, map, of } from 'rxjs';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';

@Component({
    selector: 'nemo-administration',
    standalone: true,
    templateUrl: './administration.component.html',
    styleUrl: './administration.component.scss',
    imports: [SharedModule,CommonModule]
})
export class AdministrationComponent {
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  inProcess = false;
  uploadInProgress: boolean;
  uploadProgression: number;
  json=false;

  constructor(
    private readonly bookletService: BookletService,
    private readonly toastService: ToastService,
    private readonly planEtudeRomandService:PlanEtudeRomandService
  ) {
    
  }
  /**
     * handle file from browsing
     */
  fileBrowseHandler(files) {
    this.uploadJsonObjectifsCriterias(files);
  }
  importFolioTemplate(){
    this.inProcess = true;
    this.bookletService.importFolioTemplate().subscribe({
      next: value =>{
        this.toastService.success(`L'import est un succès`);
      }, 
      error: err => {
        this.toastService.error(`L'import a échoué`);
      },
      complete: () =>{
        this.inProcess=false;
        
      }
    });
  }
  importPerFromCiip(){
    this.inProcess = true;
    this.bookletService.generatePerFromCiip().subscribe({
      next: value =>{
        this.toastService.success(`La génération est un succès`);
      }, 
      error: err => {
        this.toastService.error(`La génération a échoué`);
      },
      complete: () =>{
        this.inProcess=false;
        
      }
    });
  }

  generateJsonFromPer(){
    this.inProcess = true;
    this.planEtudeRomandService.generateJsonForChatGpt().subscribe({
      next: value =>{
        this.toastService.success(`La génération est un succès`);
        const b = new Blob([value], { type: 'application/json' });
        saveAs(b,"to-generate.json");
      }, 
      error: err => {
        this.toastService.error(`La génération a échoué`);
      },
      complete: () =>{
        this.inProcess=false;
        
      }
    });
  }
  generateLearningJsonFromPer(){
    this.inProcess = true;
    this.planEtudeRomandService.generateLearningJsonForChatGpt().subscribe({
      next: value =>{
        this.toastService.success(`La génération est un succès`);
        const b = new Blob([value], { type: 'application/json' });
        saveAs(b,"learning.json");
      }, 
      error: err => {
        this.toastService.error(`La génération a échoué`);
      },
      complete: () =>{
        this.inProcess=false;
        
      }
    });
  }

  generatePerExcel(){
    this.inProcess = true;
    this.planEtudeRomandService.generateExcel();
    this.inProcess=false;
  }

  uploadJsonObjectifsCriterias(file: any) {
    // Upload
    const formData = new FormData();
    formData.append('file', file);
    this.inProcess = true;
    this.planEtudeRomandService.uploadObjectifsCriterias(formData).pipe(
      map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.uploadProgression = Math.round(event.loaded * 100 / event.total);
              break;
            case HttpEventType.Response:
              this.inProcess = false;
              return event;
          }
        }),
      catchError((error: HttpErrorResponse) => {
        console.log(error.message);
        this.toastService.error('Le téléversement du fichier n\'a pas abouti');
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          this.toastService.info("succès");
        }
      });
  }
  deleteDeprecatedFolios() {
    this.bookletService.deleteDeprecatedFolios().subscribe({
      next: value =>{
        this.toastService.success(`La suppression est un succès`);
      }, 
      error: err => {
        this.toastService.error(`La suppression a échoué`);
      },
      complete: () =>{
        this.inProcess=false;
        
      }
    });
  }
  bookletMigrateBookletGrantsToOwners(){
    this.bookletService.bookletMigrateBookletGrantsToOwners().subscribe({
      next: value =>{
        this.toastService.success(`La migration est un succès`);
      }, 
      error: err => {
        this.toastService.error(`La migration a échoué`);
      },
      complete: () =>{
        this.inProcess=false;
        
      }
    });
  }
  
}
