import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QrCodeDisplayComponent } from '../qr-code-display/qr-code-display.component';
import { ResourceTypeEnum } from '@app/model';

@Component({
  selector: 'nemo-qr-code-modal',
  standalone: true,
  imports: [QrCodeDisplayComponent],
  templateUrl: './qr-code-modal.component.html',
  styleUrl: './qr-code-modal.component.scss'
})
export class QrCodeModalComponent {

  @Input() type: ResourceTypeEnum= ResourceTypeEnum.Parcours;
  @Input() id: number = 0;
  
  constructor(public activeModal: NgbActiveModal) {
  }

}
