import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BookletSummary, Search, Category, SearchNatureEnum, Booklet } from '@app/model';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { BookletService, ReferenceDataService, PreferencesService } from '@app/core/service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportComponent } from '@app/shared/components/export/export.component';
import { NewCategorySelectorComponent } from '@app/shared/components/new-category-selector/new-category-selector.component';
import { CardComponent } from '../../../shared/components/card/card.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule } from '@angular/forms';
import { NewCategorySelectorComponent as NewCategorySelectorComponent_1 } from '../../../shared/components/new-category-selector/new-category-selector.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'nemo-booklet-search',
    templateUrl: './booklet-search.component.html',
    styleUrls: ['./booklet-search.component.scss'],
    standalone: true,
    imports: [NgIf, NewCategorySelectorComponent_1, FormsModule, RouterLink, InfiniteScrollModule, CardComponent, NgFor]
})
export class BookletSearchComponent implements OnInit {

  public private = false;
  public rpn = false;
  public planEtudeRomand = false;

  public booklets: BookletSummary[];
  public filter: Search;
  public loading = true;
  public endReached = false;
  public loadMore = false;
  public searching = false;
  @ViewChild('cat') cat:NewCategorySelectorComponent;

  selectableCategories: string[];
  teamShared: boolean;


  constructor(public router: Router,
    private readonly bookletService: BookletService,
    private readonly route: ActivatedRoute,
    private readonly preferencesService: PreferencesService,
    private readonly referenceDataService: ReferenceDataService,
    private readonly modalService: NgbModal) { }

  ngOnInit() {
    this.filter = this.bookletService.getSearchFilter();
    switch (this.filter.nature) {
      case SearchNatureEnum.Rpn:
        this.rpn = true;
        break;
      case SearchNatureEnum.PlanEtudeRomand:
        this.planEtudeRomand = true;
        break;
      case SearchNatureEnum.Private:
        this.private = true;
        break;
      case SearchNatureEnum.TeamShared :
        this.teamShared = true;
        break;
      default:
        this.private = true;
        break;
    }
    this.route.data.subscribe((data: { booklets: BookletSummary[] }) => {
      this.booklets = data.booklets;
      this.loading = false;
    });
    this.referenceDataService.getCategoriesForRessources('folios',this.filter.nature).subscribe(cats=>{
      this.selectableCategories = cats;
    });
  }
  private fetchBooklets(concat: boolean) {
    if (!concat) {
      this.filter.skip = 0;
    }
    this.bookletService.setSearchFilter(this.filter);
    this.bookletService.getBooklets(this.filter).subscribe(booklets => {
      this.booklets = concat ? this.booklets.concat(booklets) : booklets;
      this.searching = false;
      this.loadMore = false;
      if (booklets.length < 32) {
        this.endReached = true;
      } else {
        this.endReached = false;
      }
    });
  }
  public clearFilter() {
    this.filter.title = '';
    this.filter.categories = [];
    this.filter.onlyDistributed = false;
    this.filter.planEtudeRomandId = '';
    this.searching = true;
    this.cat.reinit();
    this.fetchBooklets(false);
  }
  public newBooklet() {
    this.bookletService.getNew().subscribe((t: Booklet) => {
      this.router.navigate(['/folios/personnels',t.id, 'edition'],{ queryParams: { init: 'true' }});
    });
  }

  public search() {
    this.searching = true;
    this.fetchBooklets(false);
  }

  public bookletClicked() {

  }
  clearTerm():void {
    this.filter.title = '';
    this.fetchBooklets(false);
  }
  public filterCategory(s: any) {
    this.filter.categories= s;
    this.searching = true;
    document.querySelector('header').scrollIntoView({ behavior: 'smooth' });
    this.fetchBooklets(false);
  }

  public initFilter() {
    this.filter.title = '';
    this.filter.categories = [];
    this.fetchBooklets(false);
  }

  public isFiltered(): boolean {
    return !((this.filter.title === null || this.filter.title === '') && this.filter.categories.length === 0);
  }

  isPublisher(): boolean {
    return this.preferencesService.isPublisher();
  }
  exportList() {
    if (window && window.location) {
      const modalRef = this.modalService.open(ExportComponent, { size: 'lg' });
      modalRef.componentInstance.title = 'Partager les listes';
      modalRef.componentInstance.url = window.location.origin +
      this.router.createUrlTree([
        '/',
        'filtre',
        'folios',
        this.filter.nature.toString(),
        this.filter.title.length === 0 ? '_' : this.filter.title,
        this.filter.categories.length === 0 ? '_' : this.filter.categories.join('-')
      ]);
    }
  }

  loadMoreItems() {
    if (!this.endReached) {
      this.loadMore = true;
      this.filter.skip = this.booklets.length;
      this.fetchBooklets(true);
    }
  }

}
