import { Injectable } from '@angular/core';
import {
  Course,
  Search,
  SearchNatureEnum,
  CourseSummary,
  CourseItem,
  CourseItemSearchViewModel
} from '@app/model';
import { Observable } from 'rxjs';
import { HttpBaseService } from './http-base.service';
import { CourseRating } from '@app/model/course/course-rating';

@Injectable({
  providedIn: 'root',
})
export class ParcoursService {
  private readonly url = '/parcours';
  private searchFilter: Search;
  currentCourseUrl: string;
  cartItems: CourseItem[] = [];
  constructor(private readonly http: HttpBaseService) { }

  public getCourses(search: Search): Observable<Course[]> {
    let apiPath: string;
    switch (search.nature) {
      case SearchNatureEnum.Public: {
        apiPath = '/public';
        break;
      }
      case SearchNatureEnum.Rpn: {
        apiPath = '/rpn';
        break;
      }
      case SearchNatureEnum.TeamShared: {
        apiPath = '/equipe-pedagogique';
        break;
      }
      case SearchNatureEnum.Private: {
        apiPath = '/personnel';
        break;
      }
      case SearchNatureEnum.Enseignants: {
        apiPath = '/enseignant';
        break;
      }
      default: {
        apiPath = '/personnel';
        break;
      }
    }
    return this.http.get<Course[]>(this.url + apiPath, {
      params: {
        text: search.title.trim(),
        categories: search.categories,
        skip: search === null ? '0' : `${search.skip}`,
        onlyDistributed: search.onlyDistributed,
        planEtudeRomandId: search.planEtudeRomandId?search.planEtudeRomandId:'',
      }
    });
  }

  getCoursesDistributedByTeachers(): Observable<CourseSummary[]> {
    return this.http.get<CourseSummary[]>(`${this.url}/todo`);
  }

  getPersonnalCourses(): Observable<Course[]> {
    return this.getCourses({
      title: '',
      type:'',
      nature: SearchNatureEnum.Private,
      categories: [],
      skip: 0,
      onlyDistributed: false,
      planEtudeRomandId: ''
    });
  }

  public getParcours(id: string): Observable<Course> {
    return this.http.get<Course>(`${this.url}/${id}`);
  }

  public getNew(): Observable<Course> {
    return this.http.post<Course>(this.url, {
      title: 'Nouveau parcours',
      description: '',
      learnLanguage: 'fr',
      hintLanguage: 'fr',
      icon: 'bi bi-map-fill',
      color: 'c-grey',
    });
  }

  public createNew(course: Course): Observable<Course> {
    return this.http.post<Course>(this.url, course);
  }

  public clone(course: Course): Observable<Course> {
    return this.http.post<Course>(this.url, course);
  }

  public save(list: Course): Observable<Course> {
    return this.http.put<Course>(this.url, list);
  }

  public delete(id: number): Observable<Course> {
    return this.http.delete<Course>(`${this.url}/${id}`);
  }

  public progress(id: number, done = true): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}/progression`, {id: id, done:done});
  }

  public clearProgress(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}/progression/${id}`);
  }

  public rate(courseRating: CourseRating): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}/note/`, courseRating);
  }
  public propose(id: number): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}/propose/${id}`,{});
  }

  public getSearchFilter(): Search {
    if (this.searchFilter) {
      return this.searchFilter;
    } else {
      return new Search();
    }
  }
  public setSearchFilter(filter: Search) {
    this.searchFilter = filter;
  }
  public setCurrentCourseUrl(url: string) {
    this.currentCourseUrl = url;
  }

  public getCurrentCourseUrl() {
    return this.currentCourseUrl;
  }

  addItemToCart(item: CourseItem) {
    if (this.cartItems.indexOf(item) < 0) {
      this.cartItems.push(item);
    }
  }
  getCartItems(): CourseItem[] {
    return this.cartItems;
  }
  searchResources(term: string, skip: number, type: string, categories: string[]): Observable<CourseItemSearchViewModel[]>  {
    return this.http.get<CourseItemSearchViewModel[]>(`${this.url}/ressources?term=${term}&skip=${skip}&type=${type}${categories.map(c=>"&categories="+c).join('')}`);
  }

  public detectRouteByUrl(): Observable<any> {
    return this.http.get<any>(`${this.url}/detected-route-by-url`);
  }

  public getSecretLink(id: number): Observable<string> {
    return this.http.get<string>(`${this.url}/${id}/secret-link`);
  }

}
