<ul ngbNav #nav="ngbNav" class="nav-tabs">
  <li ngbNavItem>
    <button ngbNavLink>Lien direct</button>
    <ng-template ngbNavContent>
      <p class="mt-2">
        Un Code QR qui mène directement aux parcours.
      </p>
    <p class="text-center">
        <qr-code value="{{url()}}"
             size="200"
             errorCorrectionLevel="M"
             centerImageSrc="/assets/icons/icon-144x144.png"
             centerImageSize="50"
             margin="2">
      </qr-code><br>
      Le code à saisir<br>
      <nemo-code-display [code]="id"></nemo-code-display>
      Le lien sur la ressource<br>
      <nemo-code-display [code]="url()"></nemo-code-display>
    </p></ng-template>
  </li>
  <li ngbNavItem>
    <button ngbNavLink>Code à saisir</button>
    <ng-template ngbNavContent>
      <p class="mt-2">
        Un Code QR unique qui ouvre un formulaire pour saisir un numéro (peut-être plus simple pour un jeune).
        Il suffit pour ceci de sélectionner <strong><i class="bi bi-qr-code"></i> Code QR ?</strong> dans le bas de page d'iClasse.
      </p>
      <p class="text-center">
          <qr-code value="{{urlstatic}}"
               size="200"
               errorCorrectionLevel="M"
               centerImageSrc="/assets/icons/icon-72x72.png"
               centerImageSize="50"
               margin="2">
        </qr-code><br>
        Le code à saisir<br>
        <nemo-code-display [code]="id"></nemo-code-display>
        La page du formulaire<br>
        <nemo-code-display [code]="urlstatic"></nemo-code-display>
      </p>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>