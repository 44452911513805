import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ParcoursService, ReferenceDataService } from '@app/core/service';
import {  CourseItemSearchViewModel, Search, SearchNatureEnum } from '@app/model';
import { ParcoursRessourcesDetailComponent } from '../parcours-ressources-detail/parcours-ressources-detail.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule } from '@angular/forms';
import { NewCategorySelectorComponent } from '../new-category-selector/new-category-selector.component';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'nemo-parcours-ressources-recherche',
    templateUrl: './parcours-ressources-recherche.component.html',
    styleUrls: ['./parcours-ressources-recherche.component.scss'],
    standalone: true,
    imports: [RouterLink, NewCategorySelectorComponent, FormsModule, InfiniteScrollModule, ParcoursRessourcesDetailComponent, CommonModule]
})
export class ParcoursRessourcesRechercheComponent implements OnInit {
  @Input() selectionMode = false; 
  searching= false;
  resources: CourseItemSearchViewModel[] = [];
  loading = false;
  endReached = false;
  loadMore = false;
  skip= 0;
  filter: Search;
  @ViewChild('cat') cat: NewCategorySelectorComponent;
  @Output() add = new EventEmitter<CourseItemSearchViewModel>();


  selectableCategories: string[];
  constructor(
    private readonly parcoursservice: ParcoursService,
    private readonly referenceDataService: ReferenceDataService
  ){}
  ngOnInit(): void {
    this.filter = this.parcoursservice.getSearchFilter();
    this.referenceDataService.getCategoriesForRessources('parcours',SearchNatureEnum.Rpn).subscribe(cats=>{
      this.selectableCategories = cats;
      this.fetch(false);
    });
    
  }

  search(): void
  {
    this.fetch(false);
  }
  filterCategory(s: any) {
    this.filter.categories= s;
    document.querySelector('header').scrollIntoView({ behavior: 'smooth' });
    this.fetch(false);
  }
  clearTerm():void {
    this.filter.title = '';
    this.fetch(false);
  }
  clearFilter():void{
    this.filter.title = '';
    this.filter.type = '';
    this.filter.categories = [];
    this.resources =[];
    this.cat.reinit();
    this.fetch(false);
  }
  public fetch(concat: boolean) {
    this.searching = true && !concat;
    if (!concat) {
      this.skip = 0;
    }
    this.loadMore = true;
    this.parcoursservice.setSearchFilter(this.filter);
    this.parcoursservice.searchResources(this.filter.title,this.skip, this.filter.type, this.filter.categories).subscribe(resources => {
      this.resources = concat ? this.resources.concat(resources) : resources;
      this.searching = false;
      this.loadMore = false;
      if (resources.length === 0) {
        this.endReached = true;
      } else {
        this.endReached = false;
      }
    });
    
  }
  loadMoreItems(): void {
    if (!this.endReached) {
      this.loadMore = true;
      this.skip = this.resources.length;
      this.fetch(true);
    }
  }
  toggleType(type: string):void {
    if(this.filter.type === type){
      this.filter.type = '';
    } else {
      this.filter.type = type;
    }
    this.fetch(false);
  }
  ressourceToAdd(ressource: CourseItemSearchViewModel):void {
    this.add.emit(ressource);
  }

}
