<nemo-basepage [title]="'Folios'">
    <div page-body>
    <div class="d-flex justify-content-end">
      <small><a href="https://portail.rpn.ch/administration/biblio/Documents/Evaluation/AnimEval.pdf" target="_blank" rel="noopener noreferrer"><i class="bi bi-lock-fill"></i> Les animateurs-trices sont là pour vous aider.</a></small>
    </div>
      <ul class="nav nav-tabs mb-3">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['./personnels']">Personnels</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['./equipe-pedagogique']">Partagés</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['./rpn']">RPN
            <span class="bi bi-circle-fill" *ngIf="newrpn > 0"><span class="visually-hidden">De nouveaux folios RPN disponibles</span></span>
          </a>
        </li>
        <li class="nav-item" *ngIf="publisher">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['./plan-etude-romand']">Plan d'étude romand</a>
        </li>
      </ul>
      <router-outlet></router-outlet>
    </div>
</nemo-basepage>

