<nemo-basepage [title]="'Modifier la liste'">
  <div page-body *ngIf="userForm">
    <form [formGroup]="userForm">
      <div class="row">
        <div class="col-lg-4">
          <div>
            <label class="form-label" for="categories" class="visually-hidden">Apparence</label>
            <div class="logo mb-2">
              <nemo-logo [editable]="true" [color]="list.color" [icon]="list.icon" (editedLogo)="updateLogo($event)">
              </nemo-logo>
            </div>
          </div>
          <div class="form-floating mb-2">
            <input type="text" name="title" id="title" class="form-control form-control-lg" placeholder="Titre"
              formControlName="title" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
              maxlength="150" />
              <label for="title">Titre</label>
          </div>
          <div class="form-floating mb-2">
            <input type="text" name="description" id="description" class="form-control" placeholder="Description"
              formControlName="description" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
              maxlength="999" />
              <label for="description">Description</label>
          </div>
        </div>
        <div class="col-lg-8" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
          <div class="card mb-2">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <span class="fw-bold">Général</span>
              </li>
              <li class="list-group-item">
                <div class="d-flex flex-column align-self-center">
                  <div>
                    <span class="lead me-2">Discipline</span>
                  </div>
                  <div class="flex-grow-1">
                     <nemo-category name="categories" [selectedCategoryKeys]="list.categories" (changed)="updateClassification($event)"></nemo-category>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card mb-2" *ngIf="publisher">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <span class="fw-bold">Options de publication</span>
              </li>
              <li class="list-group-item">
                <div class="d-flex flex-wrap py-1">
                  <div class="flex-grow-1 align-self-center pe-2">
                    <label class="lead form-check-label" for="lexicon">Publier dans le lexique ?</label>
                    <span class="lead"></span>
                  </div>
                  <div class="align-self-center">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="lexicon" [(ngModel)]="list.lexicon" (change)="itemUpdated()" [ngModelOptions]="{ standalone: true }">
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <button type="button" form="userForm" class="btn btn-outline-secondary" tooltip="Retour"
            tooltipPlacement="top" [disabled]="userForm.valid && userForm.dirty"
            [routerLink]="['../']">
            <span class="bi bi-arrow-left"></span> Retour
          </button>
          <button type="button" form="userForm" class="btn btn-outline-secondary" tooltip="Supprimer"
            tooltipPlacement="top" [disabled]="userForm.valid && userForm.dirty" (click)="deleteList()">
            <span class="bi bi-trash-fill"></span> Supprimer
          </button>
          <button type="submit" form="userForm" class="btn btn-primary" tooltip="Sauvegarder" tooltipPlacement="top"
            [disabled]="!userForm.valid || !userForm.dirty" (click)="submitForm()">
            <span class="bi bi-save-fill"></span> Sauvegarder
          </button>
          <button type="button" class="btn btn-link" (click)="collapse.toggle()"  [attr.aria-expanded]="!isCollapsed"
            >{{isCollapsed ? 'Plus de détails':'Moins de détails'}}</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ul ngbNav #nav="ngbNav" class="nav-tabs nav-fill" [(activeId)]="activeId">
            <li [ngbNavItem]="1">
              <a ngbNavLink><span class="bi bi-bookmarks-fill"></span> Items</a>
              <ng-template ngbNavContent>
                <div class="row mb-2">
                  <div class="col-md-12">
                    <div class="card text-dark bg-body-tertiary my-2">
                      <div class="card-body">
                        <span class="lead" *ngIf="this.userForm.get('learnLanguage').value.id === this.userForm.get('hintLanguage').value.id">
                          Cette liste est rédigée <em>{{ getLanguageDescription(this.userForm.get('learnLanguage').value) }}</em>,
                          <strong>c'est important pour la synthèse vocale</strong>.
                        </span>
                        <span class="lead" *ngIf="this.userForm.get('learnLanguage').value.id !== this.userForm.get('hintLanguage').value.id">
                          Les éléments à apprendre dans de cette liste sont
                          <em>{{ getLanguageDescription(this.userForm.get('learnLanguage').value) }}</em> et les indices sont
                          <em>{{ getLanguageDescription(this.userForm.get('hintLanguage').value) }}</em>,
                          <strong>c'est important pour la synthèse vocale</strong>.
                        </span>
                        <div class="row mt-1">
                          <div class="col-sm-6">
                            <select id="learnLanguage" class="form-select form-select-sm" formControlName="learnLanguage">
                              <option *ngFor="let language of availableLanguages" [ngValue]="language">
                                {{ language.label }}
                              </option>
                            </select>
                            <small class="text-muted">langue de l'élément à apprendre</small>
                          </div>
                          <div class="col-sm-6">
                            <select id="hintLanguage" class="form-select form-select-sm" formControlName="hintLanguage">
                              <option *ngFor="let language of availableLanguages" [ngValue]="language">
                                {{ language.label }}
                              </option>
                            </select>
                            <small class="text-muted">langue de l'indice textuel</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sticked-top">
                    <div class="card mb-2">
                      <div class="card-body">
                        <div class="d-flex">
                          <div class="flex-grow-1 me-2 d-grid gap-2">
                            <button type="button" class="btn btn-outline-secondary" tooltip="Ajouter un item" tooltipPlacement="top" (click)="addItem()"><span class="bi bi-plus-circle-fill"></span> Ajouter un item</button>
                          </div>
                          <nemo-action-menu>
                            <button ngbDropdownItem (click)="importFromText()">Importer depuis du texte</button>
                            <button ngbDropdownItem *ngIf="conjugaisonEnabled" (click)="generateConjugaisonItems()">Ajouter un verbe conjugé</button>
                            <button ngbDropdownItem (click)="switch()">Intervertir</button>
                            <div class="dropdown-divider"></div>
                            <button ngbDropdownItem class="text-warning" (click)="dropLearns()">Effacer les éléments à apprendre</button>
                            <button ngbDropdownItem class="text-warning" (click)="dropHints()">Effacer les indices textuels</button>
                            <button ngbDropdownItem class="text-warning" (click)="dropImgs()">Effacer les images</button>
                            <div class="dropdown-divider"></div>
                            <button ngbDropdownItem class="text-danger" (click)="dropAll()">Supprimer tous les items</button>
                          </nemo-action-menu>
                          <div class="ms-2">
                            <button type="submit" form="userForm" class="btn btn-outline-primary" tooltip="Sauvegarder" tooltipPlacement="top" [disabled]="!userForm.valid || !userForm.dirty" (click)="submitForm()"><span class="bi bi-save-fill"></span></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" [dragula]="'dnditems'" [(dragulaModel)]="list.items">
                    <div *ngFor="let item of list.items; let i = index" class="col-md-12">
                      <nemo-liste-item #formRow [item]="item" [advanced]="!isCollapsed" (updated)="itemUpdated()" (focusRecieved)="setCurrent(i)"
                        (imageChanged)="openUploadImg(item)" (deleted)="deleteItem(item)" (movedup)="moveItemUp(item)"
                        (moveddown)="moveItemDown(item)"></nemo-liste-item>
                    </div>
                    <p #bottomElem></p>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" *ngIf="teacher">
              <a ngbNavLink><span class="bi bi-people-fill"></span> Équipe pédagogique</a>
              <ng-template ngbNavContent>
                <nemo-educational-team-edit [(educationalTeam)]="list.educationalTeam" (educationalTeamChange)="educationalTeamChanged()">
                  <button type="submit" form="userForm" class="btn btn-outline-primary" tooltip="Sauvegarder" tooltipPlacement="top" [disabled]="!userForm.valid || !userForm.dirty" (click)="submitForm()"><span class="bi bi-save-fill"></span></button>
                </nemo-educational-team-edit>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </form>
  </div>
</nemo-basepage>
