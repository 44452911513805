import { CourseSummary } from './coursesummary';
import { CourseItem } from './courseitem';
import { UserRight } from '../user-right';

export class Course extends CourseSummary {

  description: string;
  planEtudeRomandIds: string[];
  items: CourseItem[];
  educationalTeam: UserRight[];

  constructor(model?: Course) {
    super(model);
    if (model) {
      this.description = model.description;
      this.items = model.items;
      this.planEtudeRomandIds = model.planEtudeRomandIds;
      this.educationalTeam =model.educationalTeam;
    }
  }
}
