import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ListeService, ReferenceDataService, PreferencesService } from '@app/core/service';
import { Category, ItemList, ItemListSummary, Search, SearchNatureEnum } from '@app/model';
import { ExportComponent } from '@app/shared/components/export/export.component';
import { NewCategorySelectorComponent } from '@app/shared/components/new-category-selector/new-category-selector.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CardComponent } from '../../../shared/components/card/card.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule } from '@angular/forms';
import { NewCategorySelectorComponent as NewCategorySelectorComponent_1 } from '../../../shared/components/new-category-selector/new-category-selector.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'nemo-listes-recherche',
    templateUrl: './listes-recherche.component.html',
    styleUrls: ['./listes-recherche.component.scss'],
    standalone: true,
    imports: [NgIf, NewCategorySelectorComponent_1, FormsModule, RouterLink, InfiniteScrollModule, CardComponent, NgFor]
})
export class ListesRechercheComponent implements OnInit {
  @Input() lexique = false;
  public rpn = false;
  public private = false;

  public lists: ItemListSummary[];
  public filter: Search;
  public categories: Category[];
  public loading = false;
  public endReached = false;
  public loadMore = false;
  public searching = false;
  @ViewChild('cat') cat:NewCategorySelectorComponent;
  selectableCategories: string[];
  teamShared: boolean;
  constructor(
    public router: Router,
    private readonly listeservice: ListeService,
    private readonly route: ActivatedRoute,
    private readonly referenceDataService: ReferenceDataService,
    private readonly preferencesService: PreferencesService,
    private readonly modalService: NgbModal
  ) {

  }

  ngOnInit() {
    this.filter = this.listeservice.getSearchFilter();
    switch (this.filter.nature) {
      case SearchNatureEnum.Rpn:
        this.rpn = true;
        break;
      case SearchNatureEnum.Lexique:
        this.lexique = true;
        break;
      case SearchNatureEnum.Private:
        this.private = true;
        break;
      case SearchNatureEnum.TeamShared :
        this.teamShared = true;
        break;
      default:
        this.private = true;
        break;
    }
    this.route.data.subscribe((data: { lists: ItemListSummary[] }) => {
      this.lists = data.lists;
      this.loading = false;
    });
    this.referenceDataService.getCategoriesForRessources('listes',this.filter.nature).subscribe(cats=>{
      this.selectableCategories = cats;
    });
  }

  private fetchLists(concat: boolean) {
    if (!concat) {
      this.filter.skip = 0;
    }
    this.listeservice.setSearchFilter(this.filter);
    this.listeservice.getLists(this.filter).subscribe(lists => {
      this.lists = concat ? this.lists.concat(lists) : lists;
      this.searching = false;
      this.loadMore = false;
      if (lists.length < 32) {
        this.endReached = true;
      } else {
        this.endReached = false;
      }
      this.loading = false;
    });
  }

  public newList() {
    this.listeservice.getNewList().subscribe((t: ItemList) => {
      this.router.navigate(['/listes/personnelles', t.id, 'edition']);
    });
  }

  public search() {
    this.fetchLists(false);
  }

  public dummy() {

  }

  public filterCategory(s: any) {
    this.filter.categories= s;
    this.searching = true;
    document.querySelector('header').scrollIntoView({ behavior: 'smooth' });
    this.fetchLists(false);
  }
  clearTerm():void {
    this.filter.title = '';
    this.fetchLists(false);
  }
  public clearFilter() {
    this.filter.title = '';
    this.filter.categories = [];
    this.searching = true;
    this.cat.reinit();
    this.fetchLists(false);
  }

  public isFiltered(): boolean {
    return !((this.filter.title === null || this.filter.title === '') && this.filter.categories.length === 0);
  }

  public hasFilterCategory(cat: Category) {
    return this.filter.categories.filter(c => c === cat.key).length > 0;
  }
  exportList() {
    if (window && window.location) {
      const modalRef = this.modalService.open(ExportComponent, { size: 'lg' });
      modalRef.componentInstance.title = 'Partager les listes';
      modalRef.componentInstance.url = window.location.origin +
      this.router.createUrlTree([
        '/',
        'filtre',
        'listes',
        this.filter.nature.toString(),
        this.filter.title.length === 0 ? '_' : this.filter.title,
        this.filter.categories.length === 0 ? '_' : this.filter.categories.join('-')
      ]);
    }
  }
  isPublisher(): boolean {
    return this.preferencesService.isPublisher();
  }

  loadMoreItems() {
    if (!this.endReached) {
      this.filter.skip = this.lists.length;
      this.loadMore = true;
      this.fetchLists(true);
    }
  }
}
