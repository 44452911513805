export enum SearchNatureEnum {
  Rpn,
  Public,
  Lexique,
  Todo,
  Private,
  Enseignants,
  PlanEtudeRomand,
  TeamShared
}
