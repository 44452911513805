import { Component, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@app/model';
import { UserDisplayComponent } from '../user-display/user-display.component';
import { TeacherSearchComponent } from '../teacher-search/teacher-search.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'nemo-transmit-modal',
    templateUrl: './transmit-modal.component.html',
    styleUrls: ['./transmit-modal.component.scss'],
    standalone: true,
    imports: [NgIf, TeacherSearchComponent, UserDisplayComponent]
})
export class TransmitModalComponent {

  @Output() transmit = new EventEmitter();
  grantedTeacher: User;

  constructor(public activeModal: NgbActiveModal) { }

  selectTeacher(teacher: User) {
    this.grantedTeacher = new User({
        displayName: teacher.displayName,
        objectGuid: teacher.objectGuid,
      });
  }

}
