<nemo-basepage>
  <div page-body>
    <h1 class="display-4"><img class="flip-vertical-bck" src="/assets/icons/logo.png" alt="logo iClasse"> iClasse</h1>
    <div class="scrollable">
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 4.1 <small>novembre 2024</small></h5> 
            </div>
            <div class="col-md">
              <p class="card-text">Des <strong>codes QR</strong> peuvent être utilisés pour ouvrir des parcours depuis un document imprimé.</p>
              <p class="card-text">Les parcours et les listes, au même titre que les folios, peuvent être partagés via l'onglet <strong>Équipe pédagogique</strong>.</p>
              <p class="card-text">Une nouvelle section dans les parcours, les folios et les listes permet de voir les ressources <em>partagées</em> par les collègues.</p>
              <p class="card-text">Une fonction de partage de parcours via un lien secret permet de partager ses parcours avec d'autres utilisateurs connectés.</p>
              <p class="card-text">Les parcours auparavant partagés par e-mail deviennent plus sécurisés et ne sont plus accessibles par tous les utilisateurs &#9888;.</p>
              <p class="card-text">Nous avons également corrigé de nombreux petits détails pour que votre expérience sur iClasse soit la plus agréable possible &#9825;.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 4.0 <small>août 2024</small></h5> 
            </div>
            <div class="col-md">
              <p class="card-text">Dans les parcours, on peut maintenant rechercher des ressources sur la base de critères.</p>
              <p class="card-text">Nous avons ajouté un lien vers la liste des animatrices évaluation.</p>
              <p class="card-text">Dans les folios, on peut maintenant ajouter l'axe thématique et un objectifs du plan d'étude romand sur un critère.</p>
              <p class="card-text">Dans les folios, on peut cloner un critère.</p>
              <p class="card-text">Dans les folios, nous avons rajouté une grille d'observation pour la classe au format Excel.</p>
              <p class="card-text">La longueur des critères a été revue à la hausse.</p>
              <p class="card-text">Nous avons à nouveau corrigé plein de petites choses pour que votre expérience iClasse soit la plus agréable possible &#9825;.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 3.13 <small>décembre 2023</small></h5> 
            </div>
            <div class="col-md">
              <p class="card-text">Les médias de type audio sont aussi publié sur notre <a href="https://www.rpn.ch/medias" target="_blank" rel="noopener noreferrer">plateforme de publication de vidéo</a>.</p>
              <p class="card-text">Nous avons corrigé plein de petites choses pour que votre expérience iClasse soit la plus agréable possible.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 3.12 <small>novembre 2023</small></h5> 
            </div>
            <div class="col-md">
              <p class="card-text">Nous avons revu l'affichage et la sélection d'apprentissages du <a href="https://portail.ciip.ch/per/domains" target="_blank">Plan d'étude romand (PER)</a>.</p>
              <p class="card-text">Nous avons corrigé plein de petites choses pour que votre expérience iClasse soit la plus agréable possible.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 3.11 <small>juillet 2023</small></h5> 
            </div>
            <div class="col-md">
              <p class="card-text">Maintenant les enseignants ont la possibilité de <a [routerLink]="['/parcours/ressources']">rechercher des ressources</a> par type de ressources et par discplines.</p>
              <p class="card-text">On a ajouté une date de publication dans les ressources des parcours.</p>
              <p class="card-text">Un bouton pour réinitialiser le terme de recherche a été mis en place.</p>
              <p class="card-text">On a corrigé plein de petites choses pour que votre expérience iClasse soit la plus agréable possible.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 3.90 <small>avril 2023</small></h5> 
            </div>
            <div class="col-md">
              <p class="card-text">Les distributions des parcours pour les enseignants sont plus flexibles. On peut dorénavant spécifier une plage horaire pour chaque élève individuellement.</p>
              <p class="card-text">La recherche dans les parcours est plus fiable.</p>
              <p class="card-text">On a corrigé plein de petites choses pour que votre expérience iClasse soit la plus agréable possible.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 3.80 <small>mars 2023</small></h5> 
            </div>
            <div class="col-md">
              <p class="card-text">Un bug empêchait de voir les bons pourcentages dans les distributions de parcours.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 3.70 <small>mars 2023</small></h5> 
            </div>
            <div class="col-md">
              <p class="card-text">On a corrigé plein de petites choses pour que votre expérience iClasse soit la plus agréable possible.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 3.60 <small>février 2023</small></h5> 
            </div>
            <div class="col-md">
              <p class="card-text">Ajout d'une option dans une ressource de type textuel d'un parcours pour activer ou désactiver la progression.</p>
              <p class="card-text">Lors de la copie d'un parcours, un bug empêchait la copie des progressions et des attentes du PER, ce bug a été corrigé.</p>
              <p class="card-text">Nous avons retiré la possibilité de mettre des titres de parcours sur plus d'une ligne au profit d'un sous-titre.</p>
              <p class="card-text">Nous avons revu et agrémenter l'affichage des progressions et attentes du PER, ainsi que les années choisies pour les parcours.</p>
              <p class="card-text">On a corrigé plein de petites choses pour que votre expérience iClasse soit la plus agréable possible.</p>
            </div>
          </div>
        </div>
      </div>    
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 3.50 <small>décembre 2022</small></h5> 
            </div>
            <div class="col-md">
              <p class="card-text">Parcours - Correction d'un bug d'affichage de l'écran recherche sur mobile.</p>
              <p class="card-text">Parcours - Correction d'un bug d'affichage sur une ressource sans titre ou sans description.</p>
            </div>
          </div>
        </div>
      </div>     
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 3.40 <small>décembre 2022</small></h5> 
            </div>
            <div class="col-md">
              <p class="card-text">Folio - Désactivation de l'Échelle d'évaluation classique</p>
            </div>
          </div>
        </div>
      </div> 
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 3.30 <small>novembre 2022</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Plusieurs modifications et corrections qui peuvent être résumées de la manière suivante :</p>
              <p class="card-text"></p>
              <p class="card-text">Ajout d'un bouton "Proposer le parcours au SEO" dans un parcours</p>
              <p class="card-text">Correction d'un bug dans la généraiton d'une liste de conjugaison</p>
              <p class="card-text">Titre formattable dans l'ajout d'une ressource d'un parcours</p>
              <p class="card-text">Titre sur plusieurs lignes pour un parcours</p>
              <p class="card-text">Recherche URL - Correction</p>
              <p class="card-text">Nouvelle catégorie - Moyen d'enseignement neuchâtelois</p>
              <p class="card-text">Parcours - Pastilles Attentes - Autre couleur</p>
              <p class="card-text">Folio - Migration d'un Folio personnel en Folio RPN (admin)</p>
            </div>
          </div>
        </div>
      </div>    
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 3.20 <small>octobre 2022</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Une nouvelle catégorie de discipline "Moyen d'enseignement officiel" est disponible dans l'édition d'un parcours.</p>
              <p class="card-text">Les parcours peuvent désormais être liés au <a href="https://www.plandetudes.ch/" target="_blank">Plan d'étude romand (PER)</a> tant au niveau des progressions qu'au niveau des attentes. La totalité du PER a été importée et est accessible à travers iClasse.</p>
              <p class="card-text">L'écran de recherche des parcours intègre également les progressions et les attentes du PER.</p>
            </div>
          </div>
        </div>
      </div>    
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 3.00 <small>avril 2022</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">L'éditeur de parcours permet maintenant l'ajout d'une ressource à un endroit précis dans un parcours.</p>
              <p class="card-text">Un éditeur de texte est ajouté au choix des ressources à publier dans un parcours. Il permet de créer un commentaire, une consigne, etc. directement en lien avec une ressource.</p>
              <p class="card-text">Une ressource de type texte fait partie intégrante du parcours et doit être marquée comme « lue » pour progresser dans le parcours.</p>
              <p class="card-text">Des raccourcis clavier sont disponibles dans les activités pour faciliter les manipulations.</p>
              <p class="card-text">L'affichage de l'actualité est limité à une fois par heure.</p>
              <p class="card-text">La sélection d'une période de distribution a été améliorée. </p>
              <p class="card-text">L'écran de gestion des préférences a été amélioré.</p>
              <p class="card-text">L'aide en ligne a été améliorée afin de la rendre plus lisible et accessible.</p>
              <p class="card-text">L'activité associer a été revue pour rendre les cartes plus cohérentes entre elles.</p>
              <p class="card-text">Les différents types des ressources sont signalés par de petites icônes.</p>
              <p class="card-text">Le raccourci clavier CTRL + S permet de sauvegarder rapidement un parcours, une liste ou un folio.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.26 <small>février 2022</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Nous avons corrigé un bug qui peut ralentir la sauvegarde des folios avec beaucoup de critères.</p>
              <p class="card-text">On a corrigé plein de petites choses pour que votre expérience iClasse soit la plus agréable possible.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.25 <small>janvier 2022</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Un <a  [routerLink]="['/listes/conjugaison']">outil de conjugaison</a> permet de réaliser des listes de mémorisation rapidement en français, allemand et anglais. Cet outil est aussi disponible dans l'éditeur de liste.</p>
              <p class="card-text">Le nom de l'élève apparaît sur chaque page des rapport des Folios.</p>
              <p class="card-text">On a corrigé plein de petites choses pour que votre expérience iClasse soit la plus agréable possible.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.24 <small>novembre 2021</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Un <a [routerLink]="['listes','impression','641']">système pour imprimer des cartes</a> à partir des listes de mémorisation a été ajouté.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.22 <small>septembre 2021</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Il est maintenant possible de créer un parcours avec toutes les activités possible pour une liste.</p>
              <p class="card-text">Ajout de la couleur pour la thématique <a href="https://www.plandetudes.ch/web/guest/education-numerique" target="_blank" rel="noopener noreferrer">Éducation numérique</a></p>
              <p class="card-text">Et des corrections diverses pour faciliter votre utilisation d'iClasse.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.21 <small>mai 2021</small></h5>
            </div>
            <div class="col-md">

              <p class="card-text">Nous avons ajouté la possibilité de définir des heures de départ et de fin pour les distributions.</p>
              <p class="card-text">Un message sur la santé s'affiche après 30 minutes d'utilisation.</p>
              <p class="card-text">Les parcours terminés pour les élèves sont toujours affichés pour autant que la distribution soit toujours en cours.</p>
              <p class="card-text">Les distributions sont supprimées en fin d'année scolaire sauf si l'option <strong>Garder en fin d'année scolaire</strong> est activée sur la distribution.</p>
              <p class="card-text">Nous avons corrigé un bug de l'activité Grille qui empêchait de sélectionner certains mots qui se chevauchent.</p>
              <p class="card-text">Nous avons amélioré les performances générales de la plateforme pour une expérience de navigation plus fluide.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.20 <small>avril 2021</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Nous avons corrigé un problème qui empéchait l'ouverture de ressources sur iOS et macOS.</p>
              <p class="card-text">Nous avons ajouté des pictogrammes supplémentaires.</p>
              <p class="card-text">Une nouvelle section <a [routerLink]="['/parcours/distributions']">Distributions</a> dans les parcours permet de consulter les parcours actuellement distribués.</p>
              <p class="card-text">Nous avons modifié l'ordre des disciplines dans les rapports folio pour mettre en premier la discipline capacités transversales.</p>
              <p class="card-text">Un système permet de contrôler les urls des ressources saisies afin d'éviter les fautes de frappe.</p>
              <p class="card-text">Et des corrections diverses pour améliorer votre expérience sur iClasse.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.19 <small>mars 2021</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Nous avons mis en place une fenêtre modale pour l'affichage des vidéos publiées sur notre plateforme.</p>
              <p class="card-text">Il est maintenant possible de téléverser des fichiers audio.</p>
              <p class="card-text">Un panier de ressources permet de sélectionner des ressources de divers parcours pour en créer un nouveau.</p>
              <p class="card-text">Corrections diverses pour une meilleure expérience.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.18 <small>février 2021</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Nous avons corrigé un bug qui pouvait afficher plusieurs observations à double sur les rapports des folios.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.17 <small>décembre 2020</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Nous avons intégré la mise à disposition sécurisée de vidéo via notre <a href="https://www.rpn.ch/medias" target="_blank" rel="noopener noreferrer">plateforme de publication de vidéo</a>.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.16 <small>avril 2020</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Nous avons corrigé un bug qui empêchait l'affichage du graphique de progression sur le détail d'un critère.</p>
              <p class="card-text">Nous avons amélioré les performances lors de l'affichage d'un folio.</p>
              <p class="card-text">Nous avons revu les urls de l'applications pour qu'elles soient plus explicites.</p>
              <p class="card-text">Nous indiquons de manière plus visible qu'un parcours est distribué ou est prévu à la distribution.</p>
              <p class="card-text">Nous avons amélioré la saisie d'une ressources interne à iClasse en supprimant le chemin de base de l'application automatiquement.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.15 <small>avril 2020</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Nous avons revu l'affichage des parcours distribués à l'élève.</p>
              <p class="card-text">Nous avons ajouté, pour les enseignants, la possiblité de rechercher les parcours distribués.</p>
              <p class="card-text">Nous avons ajouté un bouton de sauvegarde toujours visible sur l'édition des parcours et des listes.</p>
              <p class="card-text">Corrections diverses pour une meilleure expérience.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.14 <small>avril 2020</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Corrections diverses pour une meilleure expérience.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.10 <small>avril 2020</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Nous avons ajouté une description plus précise des couleurs disponibles en adéquation avec le Plan d'étude romand.</p>
              <p class="card-text">Nous avons corrigé un problème qui pouvait empêcher la synthèse vocale.</p>
              <p class="card-text">Nous avons volontairement limité la taille maximale des fichiers que vous pouvez téléverser afin de soulager les infrastructures.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.9 <small>mars 2020</small></h5>
            </div>
            <div class="col-md">

            <p class="card-text">Nous avons mis à jour de l'aide en ligne</p>
            <p class="card-text">Nous avons ajouté une option de masque optionnelle dans les activités, elle permet de cacher une partie de l'élément à apprendre.</p>
            <p class="card-text">Correction diverses pour une meilleure expérience</p>
          </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.8 <small>février 2020</small></h5>
            </div>
            <div class="col-md">

            <p class="card-text">Nouvelle section Enseignant·e·s pour les parcours destinés aux enseignant·e·s uniquement.</p>
            <p class="card-text">Une fonction de focus lors de l'édition des listes et parcours vous facilite la vie lors de l'édition.</p>
            <p class="card-text">Nous avons ajouté un lien pour nous contacter.</p>
            <p class="card-text">Nous avons changé le titre de l'activité Grille par Repérer</p>
            <p class="card-text">Nous avons ajouté un système d'actualités pour mieux vous tenir au courant des nouveautés d'iClasse</p>
          </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.7 <small>décembre 2019</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Donner votre avis sur iClasse via les préférences utilisateur.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.6 <small>décembre 2019</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Nouvelle activité de traduction sur les listes avec deux langues différentes.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.5 <small>novembre 2019</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Améliorations diverses sur les activités pour une meilleure expérience utilisateur.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.4 <small>novembre 2019</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Les rapports ont été mis à jour en fonction des demandes des utilisateurs.</p>
              <p class="card-text">Auto validation des observations dans les folios.</p>
              <p class="card-text">Choix du nombre d'éléments dans l'activité Associer.</p>
              <p class="card-text">Ajout de l'indice visuel dans l'activité Écrire.</p>
              <p class="card-text">Corrections diverses pour une meilleure expérience.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.3 <small>juillet 2019</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Corrections mineures.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.2 <small>juillet 2019</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Fonctionnalité de suivi du travail (folio).</p>
              <p class="card-text">Amélioration de l'accessibilité.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.1 <small>mai 2019</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Amélioration de la synthèse vocale.</p>
              <p class="card-text">Fonction pour donner son avis sur un parcours.</p>
              <p class="card-text">Ajout d'un bouton pour le retour en haut de page.</p>
              <p class="card-text">Corrections diverses pour une meilleure expérience.</p>
            </div>
            </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md me-1">
              <h5 class="card-title">Version 2.0 <small>mars 2019</small></h5>
            </div>
            <div class="col-md">
              <p class="card-text">Affichage de l'indice dans l'activité Écrire.</p>
              <p class="card-text">Ajout d'items à une liste existante.</p>
              <p class="card-text">Chronomètre des activités invisible.</p>
              <p class="card-text">Export d'une liste</p>
              <p class="card-text">Notion d'item réceptif</p>
              <p class="card-text">Tags sur les items des listes.</p>
              <p class="card-text">Corrections diverses pour une meilleure expérience.</p>
            </div>
        </div>
      </div>
      </div>
  </div>
</div>
</nemo-basepage>