<div class="modal-header">
  <h4 class="modal-title">Code QR</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="my-2">    
   <nemo-qr-code-display [type]="type" [id]="id"></nemo-qr-code-display>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()">Fermer</button>
</div>

