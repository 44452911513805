import { Component, Input } from '@angular/core';
import { QrCodeModule } from 'ng-qrcode';
import { CodeDisplayComponent } from '../code-display/code-display.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ResourceTypeEnum } from '@app/model';
import { SettingsService } from '@app/core/service/settings.service';

@Component({
  selector: 'nemo-qr-code-display',
  standalone: true,
  imports: [QrCodeModule,CodeDisplayComponent,NgbNavModule],
  templateUrl: './qr-code-display.component.html',
  styleUrl: './qr-code-display.component.scss'
})
export class QrCodeDisplayComponent {

  @Input() type: ResourceTypeEnum= ResourceTypeEnum.Parcours;
  @Input() id: number = 0;
  qrEnabled: boolean;

  constructor(private readonly settingsService: SettingsService) {
    this.qrEnabled = this.settingsService.feature_flags.qr;
    
  }
  
  url(){
    return `${this.settingsService.qrUrl}/${this.type}/${this.id}`;
  }

  urlstatic:string = `${this.settingsService.qrUrl}/qr`;
}
