<nemo-basepage>
  <div page-body>
    <div class="col-md-4 offset-md-4 text-center" *ngIf="userForm">
      <div class="card m-3 p-3">
        <h1>
          <i class="bi bi-qr-code"></i>
        </h1>
        <form [formGroup]="userForm" (ngSubmit)="sumbitCode()">
          <p class="lead">Taper le code qui vous a été transmis.</p>
          <div class="input-group input-group-lg">
            <span class="input-group-text" id="inputGroup-sizing-lg">Code</span>
            <input type="number" class="form-control" nemoFocus name="code" id="code" formControlName="code" min="1" max="999999" aria-label="Code" aria-describedby="inputGroup-sizing-lg">
            <button type="submit" class="btn btn-outline-secondary" [disabled]="!userForm.valid">OK!</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</nemo-basepage>
