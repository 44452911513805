import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { User } from '@app/model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TeacherSearchInputComponent } from '../teacher-search-input/teacher-search-input.component';

@Component({
    selector: 'nemo-teacher-search',
    templateUrl: './teacher-search.component.html',
    styleUrls: ['./teacher-search.component.scss'],
    standalone: true,
    imports: [FormsModule, CommonModule,TeacherSearchInputComponent]
})
export class TeacherSearchComponent implements OnInit {

  
  deleteEnabled = true;
  @Output() addTeacher = new EventEmitter<User>(true);
  @Output() dropTeachers = new EventEmitter();

  ngOnInit() {
    this.deleteEnabled = this.dropTeachers.observers.length > 0;
  }

  dropAll() {
    this.dropTeachers.emit();
  }
}
