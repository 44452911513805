import { User } from "./user";

export class UserRight extends User {
  
  public canEdit : boolean;
  
  constructor(model?: UserRight) {
    super(model);
    if (model) {
      this.canEdit = model.canEdit;
    }
  }

}
