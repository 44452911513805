import { Component, OnInit } from '@angular/core';
import { PreferencesService, ReferenceDataService } from '@app/core/service';
import { NgIf } from '@angular/common';
import { RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';

@Component({
    selector: 'nemo-listes',
    templateUrl: './listes.component.html',
    styleUrls: ['./listes.component.scss'],
    standalone: true,
    imports: [BasepageComponent, RouterLinkActive, RouterLink, NgIf, RouterOutlet]
})
export class ListesComponent implements OnInit {

  newrpn = 0;
  newlexicon = 0;

  constructor(private readonly referenceDataService: ReferenceDataService,
    private readonly preferencesService: PreferencesService,
  ) { }

  ngOnInit() {
    this.newrpn = this.referenceDataService.getNewState().newRpnLists;
    this.newlexicon = this.referenceDataService.getNewState().newLexicons;
  }
  isTeacher(): boolean {
    return this.preferencesService.isTeacher();
  }
}

