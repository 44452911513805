<nemo-basepage *ngIf="list" [title]="list.lexicon ? 'Lexique' : 'Liste'">
  <div page-body>
    <div class="row" *ngIf="list.rpn || list.lexicon">
      <div class="col-md-12">
        <p class="lead" *ngIf="list.lexicon">
          Créer une liste en sélectionnant tout ou une partie des items de ce lexique.
        </p>
        <p class="lead" *ngIf="list.rpn">
          Cette liste est prête à l'emploi, utilisez-la directement ou bien copiez-la pour la modifier selon vos
          besoins.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="sticked-top-margin">
          <nemo-card [big]="true" [title]="list.title" [subtitle]="list.description" [ribbon]="list.ribbon" [author]="list.author"
            [icon]="list.icon" [tags]="list.categories" [color]="list.color"></nemo-card>
          <div class="my-2 d-grid gap-2">
            <button type="button" *ngIf="list.canEdit" class="btn btn-outline-primary" tooltip="Modifier"
              tooltipPlacement="top" [routerLink]="['./edition']">
              <span class="bi bi-pencil-square"></span> Modifier
            </button>
            <button type="button" *ngIf="!list.lexicon && !list.canEdit" class="btn btn-outline-primary"
              tooltip="Copier chez moi" tooltipPlacement="top" (click)="clone()">
              <span class="bi bi-clipboard-fill"></span> Copier chez moi
            </button>
            <button type="button" *ngIf="list.lexicon" [disabled]="!anySelected()"
              class="btn btn-outline-primary" tooltip="Copier la sélection chez moi" tooltipPlacement="top"
              (click)="createListWithSelectedItems()">
              <span class="bi bi-clipboard-fill"></span> Créer une liste avec la sélection
            </button>
          </div>
          <div class="d-flex my-2">
            <div class="flex-grow-1 d-grid gap-2">
              <button type="button" class="btn btn-outline-secondary" tooltip="Retour" tooltipPlacement="top"
                (click)="back()">
                <span class="bi bi-arrow-left"></span> Retour
              </button>
            </div>
            <div class="ps-2">
              <nemo-action-menu>
                <button type="button" *ngIf="!list.lexicon && list.items.length > 0" class="dropdown-item"
                  (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed">{{isCollapsed ? 'Afficher les items' : 'Cacher les items'}}</button>
                <button type="button" *ngIf="!list.lexicon" class="dropdown-item" tooltip="Créer un parcours avec toutes les activités"
                  tooltipPlacement="top"  (click)="createCourse()">
                  Créer un parcours
                </button>
                <button type="button" *ngIf="!list.lexicon" class="dropdown-item" tooltip="Imprimer des cartes de mémorisation"
                  tooltipPlacement="top"  (click)="printCards()">
                  Imprimer des cartes de mémorisation
                </button>
                <button type="button" *ngIf="!list.lexicon" class="dropdown-item" tooltip="Copier le lien"
                  tooltipPlacement="top" ngxClipboard [cbContent]="getDocumentLocation()"
                  (cbOnSuccess)="copied($event)">
                  Copier le lien
                </button>
                <button type="button" *ngIf="!list.lexicon" class="dropdown-item" tooltip="Envoyer le lien par courriel"
                  tooltipPlacement="top" (click)="sendbymail()">
                  Envoyer le lien par courriel
                </button>
                <button type="button" *ngIf="!isCollapsed && !allSelected()" class="dropdown-item"
                  tooltip="Sélectionner tout" tooltipPlacement="top" (click)="selectAll()">
                  Sélectionner tout
                </button>
                <button type="button" *ngIf="!isCollapsed && allSelected()" class="dropdown-item"
                  tooltip="Désélectionner tout" tooltipPlacement="top" (click)="unSelectAll()">
                  Désélectionner tout
                </button>
                <button type="button" *ngIf="!list.canEdit && !list.lexicon && noneSelected()" class="dropdown-item"
                  tooltip="Copier chez moi" tooltipPlacement="top" (click)="clone()">
                  Copier chez moi
                </button>
                <button type="button" *ngIf="list.canEdit && noneSelected()" class="dropdown-item" tooltip="Dupliquer"
                  tooltipPlacement="top" (click)="clone()">
                  Dupliquer
                </button>
                <button type="button" *ngIf="!list.canEdit && anySelected()" class="dropdown-item"
                  tooltip="Copier la sélection chez moi" tooltipPlacement="top" (click)="createListWithSelectedItems()">
                  Créer une liste avec la sélection
                </button>
                <button type="button" *ngIf="anySelected()" class="dropdown-item"
                  tooltip="Ajouter la sélection à une liste" tooltipPlacement="top" (click)="addSelectedItemsToList()">
                  Ajouter la sélection à une liste
                </button>
                <button type="button" *ngIf="list.canEdit && anySelected()" class="dropdown-item"
                  tooltip="Dupliquer avec la sélection" tooltipPlacement="top" (click)="createListWithSelectedItems()">
                  Dupliquer avec la sélection
                </button>
                <button type="button" class="dropdown-item" tooltip="Exporter"
                  tooltipPlacement="top" (click)="export()">
                  Exporter
                </button>
              </nemo-action-menu>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="row">
          <div class="col-md-12" *ngIf="list.items.length > 0 && !list.lexicon">
            <div class="row row-cols-1 row-cols-md-3 g-4">
              <div class="col" *ngFor="let activity of activities">
                <div class="card text-center">
                  <div class="card-body">
                    <h5 class="card-title"><span [ngClass]="[activity.icon]"></span> {{ activity.shortLabel }}</h5>
                    <div class="card-body">
                      <p class="card-text" *ngIf="isCollapsed">{{activity.caption}}</p>
                      <div class="d-grid gap-2">
                        <a [routerLink]="[activity.route, list.id]" class="btn btn-outline-secondary stretched-link">Démarrer <span class="bi bi-arrow-right"></span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12" *ngIf="activities.length === 0">
            <div class="alert alert-primary" role="alert">
              <h4 class="alert-heading">Aïe! Dommage.</h4>
              <p>Aucune activité ne semble adaptée pour cette liste.</p>
              <p *ngIf="list.canEdit">
                <a [routerLink]="['edition']" class="alert-link">Compléter</a>
                votre liste pour la rendre utilisable avec un maximum d'activités.
              </p>
            </div>
          </div>
        </div>
        <div class="row"  #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
          <div class="col-md-12" *ngIf="displayFilter">
            <nemo-tags-toggler [tags]="filters" (click)="handleFilters()"></nemo-tags-toggler>
            <button type="button" *ngIf="anyFilterActivated() && !isCollapsed && !allSelected()" class="btn btn-link"
              tooltip="Sélectionner tout" tooltipPlacement="top" (click)="selectAll()">
              Sélectionner tout
            </button>
            <button type="button" *ngIf="anyFilterActivated() && !isCollapsed && allSelected()" class="btn btn-link"
              tooltip="Désélectionner tout" tooltipPlacement="top" (click)="unSelectAll()">
              Désélectionner tout
            </button>
          </div>
          <div *ngFor="let item of list.items; let i = index" class="col-md-12">
            <nemo-liste-item *ngIf="!item.filtered" [item]="item" [edit]="false" [selectable]="true">
            </nemo-liste-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</nemo-basepage>
