import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren, QueryList, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ToastService, ParcoursService, InputValidationService, PreferencesService, ReferenceDataService } from '@app/core/service';
import { Course, CourseItem, CourseItemSearchViewModel, Logo } from '@app/model';
import { SuppressModalComponent } from '@app/shared/components/suppress-modal/suppress-modal.component';
import { NgbModal, NgbCollapse, NgbDropdownButtonItem, NgbDropdownItem, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaService, DragulaModule } from 'ng2-dragula';
import { Observable } from 'rxjs';
import { DialogService } from '@app/core/service/dialog.service';
import { CanComponentDeactivate } from '@app/core/guards/CanComponentDeactivate';
import { ImportFromTextModalComponent } from '@app/shared/components/import-from-text-modal/import-from-text-modal.component';
import { CourseItemSelectorModalComponent } from '@app/shared/components/course-item-selector-modal/course-item-selector-modal.component';
import { ParcoursItemEditionModalComponent } from '../parcours-item-edition-modal/parcours-item-edition-modal.component';
import { PlanEtudeRomandSearchViewModel } from '@app/model/plan-etude-romand/plan-etude-romand-search-view-model';
import { PlanEtudeRomandSelectorModalComponent } from '@app/shared/components/plan-etude-romand-selector-modal/plan-etude-romand-selector-modal.component';
import { ParcoursItemComponent } from '../parcours-item/parcours-item.component';
import { NgIf, NgFor } from '@angular/common';
import { ParcoursRessourcesRechercheModalComponent } from '@app/shared/components/parcours-ressources-recherche-modal/parcours-ressources-recherche-modal.component';
import { SharedModule } from '@app/shared/shared.module';

@Component({
    selector: 'nemo-parcours-modification',
    templateUrl: './parcours-modification.component.html',
    styleUrls: ['./parcours-modification.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgbCollapse, NgFor,  RouterLink,  NgbDropdownButtonItem, NgbDropdownItem, DragulaModule,  NgbNavModule, SharedModule, ParcoursItemComponent]
})
export class ParcoursModificationComponent implements OnInit, OnDestroy, CanComponentDeactivate {



  @ViewChild('bottomElem', { static: false }) BottomElement: ElementRef;
  @HostListener('window:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        this.submitForm();
        event.preventDefault();
    }
  }

  public list: Course;
  public userForm: FormGroup;
  public typelabel: string;
  public closeResult: string;
  public publisher = false;
  public teacher = false;
  public isCollapsed = true;
  @ViewChildren('formRow') rows: QueryList<ElementRef>;
  focusedItemIndex = 0;
  cartItems: CourseItem[] = [];

  constructor(public router: Router,
    private readonly service: ParcoursService,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly dragulaService: DragulaService,
    private readonly toastService: ToastService,
    public preferencesService: PreferencesService,
    private readonly modalService: NgbModal,
    public inputValidationService: InputValidationService,
    private readonly dialogService: DialogService,
    private readonly referenceDataService: ReferenceDataService
  ) {
    this.dragulaService.drag('dnditems').subscribe(() => {
      this.userForm.markAsDirty();
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    // Allow synchronous navigation (`true`) if no crisis or the crisis is unchanged
    if (!this.userForm.dirty) {
      return true;
    }
    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    return this.dialogService.confirm('Vous n\'avez pas sauvegardé les modifications, souhaitez-vous continuer sans sauvegarder ?');
  }

  ngOnInit() {
    this.cartItems = this.service.getCartItems();
    this.route.params.subscribe(params => {
      this.service.getParcours(params['id']).subscribe(list => {
        this.list = list;
        if(!list.canEdit){
          this.router.navigate(['/']);
        }
        this.focusedItemIndex = list.items.length - 1;
        this.dragulaService.createGroup('dnditems', {
          moves: function (el, source, handle, sibling) {
            return handle.className.indexOf('bi-three-dots-vertical') >= 0;
          }
        });
        this.initForm();
      });
    });
    this.publisher = this.preferencesService.isPublisher();
    this.teacher =   this.preferencesService.isTeacher();
  }

  ngOnDestroy() {
    if (this.dragulaService.find('dnditems') !== undefined) {
      this.dragulaService.destroy('dnditems');
    }
  }

  initForm() {
    this.userForm = this.formBuilder.group({
      title: [this.list.title, [Validators.required]],
      subtitle: [this.list.subtitle],
      description: [this.list.description],
      duration: [this.list.duration]
    });
  }

  submitForm(): void {
    this.list.title = this.inputValidationService.cleanItem(this.userForm.get('title').value);
    this.list.subtitle = this.inputValidationService.cleanItem(this.userForm.get('subtitle').value);
    this.list.description = this.inputValidationService.cleanItem(this.userForm.get('description').value);
    this.list.duration = this.userForm.get('duration').value;
    this.list.items.forEach(i => {
      i.description = this.inputValidationService.cleanItem(i.description);
      i.navigationUrl = this.inputValidationService.cleanRessourcesUrl(i.navigationUrl);
    });
    this.service.save(this.list).subscribe((list) => {
      this.toastService.success('Parcours sauvegardé');
      this.userForm.markAsPristine();
    });
  }

  updateLogo(logo: Logo) {
    this.list.color = logo.color;
    this.list.icon = logo.icon;
    this.userForm.markAsDirty();
  }

  deleteList() {
    const modalRef = this.modalService.open(SuppressModalComponent);
    modalRef.result.then((result) => {
      this.service.delete(this.list.id).subscribe((res) => {
        this.router.navigate(['/parcours/personnels']);
      });
    });
  }

  deleteItem(item: CourseItem) {
    this.list.items.splice(this.list.items.indexOf(item), 1);
    this.userForm.markAsDirty();
  }

  moveItemUp(item: CourseItem) {
    this.move(this.list.items, item, -1);
    this.userForm.markAsDirty();
  }

  moveItemDown(item: CourseItem) {
    this.move(this.list.items, item, 1);
    this.userForm.markAsDirty();
  }

  addItem(pos = -1) {
    const modalRef = this.modalService.open(ParcoursItemEditionModalComponent, { backdrop: 'static' ,  size: 'lg' });
    modalRef.componentInstance.item = {
      id:-this.list.items.length,
      publishedOn: new Date(),
      tags:[],
      categories:[]
    };
    modalRef.componentInstance.defaultColor = this.list.color;
    modalRef.componentInstance.defaultIcon = this.list.icon;
    modalRef.closed.subscribe(o =>{
      if(pos === -1) {
        setTimeout(() => {
          document.getElementById("end").scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }, 200);
        
      }
    });
    modalRef.result.then((result) => {
      if(pos === -1) {
        this.list.items.push(result);
      } else {
        this.list.items.splice(pos, 0, result);
      }
      this.userForm.markAsDirty();
    },()=>{});

  }

  editItem(item: CourseItem){
    const modalRef = this.modalService.open(ParcoursItemEditionModalComponent, { size: 'lg' });
    modalRef.componentInstance.item = Object.assign({}, item );
    modalRef.result.then((result) => {
      const index = this.list.items.findIndex(c => c.id === result.id);
      this.list.items[index] = new CourseItem(result);
      this.userForm.markAsDirty();
    },()=>{});
  }

  setCurrent(idx: number) {
    this.focusedItemIndex = idx;
  }

  updateClassification(cat: string[]) {
    // Changement d'icône sur l'ajout de la catégorie "Moyen d'enseignement officiel" (MER).
    if (!this.list.categories.includes('MER') && cat.includes('MER') )
    {
      let merLogo = this.referenceDataService.getLogo().icons.find(x => x.description === 'MER');
      if (merLogo)
      {
        this.list.icon = 'edicons-visualidentity-rpn';
      }
    }
    this.list.categories = cat;
    this.userForm.markAsDirty();
  }
  addPlanEtudeRomand():void{
    const modalRef = this.modalService.open(PlanEtudeRomandSelectorModalComponent,{size:'lg'});
    modalRef.result.then((result:PlanEtudeRomandSearchViewModel) => {
      this.list.planEtudeRomandIds.push(result.id);
      const i = this.list.categories.findIndex(c=>c===result.selectedDiscipline)
      if(i===-1){
        this.list.categories.push(result.selectedDiscipline);
        this.list.categories
        this.updateClassification(this.list.categories);
      }
      const j = this.list.categories.findIndex(c=>c===result.selectedYear)
      if(j===-1){
        this.list.categories.push(result.selectedYear);
        this.updateClassification(this.list.categories);
      }
      this.userForm.markAsDirty();
    },()=>{});
    
  }
  dropPer(id:string){
    const index = this.list.planEtudeRomandIds.indexOf(id, 0);
    if (index > -1) {
      this.list.planEtudeRomandIds.splice(index, 1);
      this.userForm.markAsDirty();
    }
    
  }

  itemUpdated() {
    this.userForm.markAsDirty();
  }

  addFromCart() {
    const modalRef = this.modalService.open(CourseItemSelectorModalComponent, {
      scrollable: true,
      size: 'lg'
    });
    modalRef.componentInstance.items = this.cartItems;
    modalRef.result.then(
      result => {
        result.id = -this.list.items.length;
        this.list.items.push(result);
        this.userForm.markAsDirty();
      }
    );
  }

  importFromText() {
    const modalRef = this.modalService.open(ImportFromTextModalComponent, { size: 'lg' });
    modalRef.result.then(result => {
      result.txt.split('\n').forEach(line => {
        if (line.split('\t').length <= 11) {
          const elems = line.split('\t');
          this.list.items.push({
            id: -1,
            title: elems.length > 0 ? elems[0] : '',
            navigationUrl: elems.length > 1 ? elems[1] : '',
            itemType:'Link',
            icon: this.list.icon,
            color: this.list.color,
            description: elems.length > 2 ? elems[2].split(';').filter(p=>p.length>0).map(t=>'<p>'+t+'</p>').join('') : null,
            tags:elems.length > 3 ? elems[3].split(';') : null,
            categories:[]
          });
        }
      });
      this.userForm.markAsDirty();
    });
  }
  
  searchRessource() {
    const modalRef = this.modalService.open(ParcoursRessourcesRechercheModalComponent, { size: 'xl' });
    modalRef.result.then((courseItemSearchViewModel: CourseItemSearchViewModel) => {
      this.list.items.push({
        id: -1,
        title: courseItemSearchViewModel.title,
        navigationUrl: courseItemSearchViewModel.navigationUrl,
        itemType: courseItemSearchViewModel.type,
        icon: courseItemSearchViewModel.icon,
        color: courseItemSearchViewModel.color,
        description: courseItemSearchViewModel.description,
        tags:courseItemSearchViewModel.tags,
        categories:courseItemSearchViewModel.categories,
      });
    });
    this.userForm.markAsDirty();
    
  }


  private move(array, element, delta) {
    const index = array.indexOf(element);
    const newIndex = index + delta;
    if (newIndex < 0 || newIndex === array.length) {
      return;
    } // Already at the top or bottom.
    const indexes = [index, newIndex].sort(); // Sort the indixes
    array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); // Replace from lowest index, two elements, reverting the order
  }
  educationalTeamChanged(){
    this.userForm.markAsDirty();

  }
}
