<div *ngIf="filter">
  <div class="row">
    <div class="col-lg-3 d-none d-lg-block">      
      <nemo-new-category-selector *ngIf="selectableCategories" [selectableCategoriesKeys]="selectableCategories" #cat [selectedCategoriesKeys]="filter.categories" (selectedCategoriesChange)="filterCategory($event)"></nemo-new-category-selector>
      <div class="d-grid gap-2 mb-2">
        <button type="button" class="btn btn-outline-secondary" aria-label="Réinitialiser la recherche"
          [disabled]="filter.categories.length === 0 && filter.title === '' && !selectedProgressionPer && !selectedAttentePer" (click)="clearFilter()">
          Réinitialiser la recherche
        </button>
      </div>
    </div>
    <div class="col-lg-9">
      <div class="d-flex flex-column flex-md-row align-self-center">
        <div class="flex-grow-1 mt-2 mt-md-0 me-0 me-md-2">
          <div class="d-grid gap-2">
            <div class="input-group">
              <input type="text" class="form-control" (keyup.enter)="search()" [(ngModel)]="filter.title"
                placeholder="Rechercher" aria-label="Rechercher" autocomplete="off" autocorrect="off" autocapitalize="off"
                spellcheck="false" maxlength="255" />
              <button class="btn btn-outline-secondary" (click)="clearTerm()" type="sumbit">
                <span class="bi bi-x-lg"></span>
              </button>
              <button *ngIf="isPublisher() && lexique" class="btn btn-outline-secondary" (click)="exportList()">
                <i class="bi bi-arrow-up-square"></i>
              </button>
              <button class="btn btn-outline-secondary" (click)="search()" type="sumbit">
                <span class="bi bi-search"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="mt-2 mt-md-0 mb-2">
          <div class="d-grid gap-2">
            <button type="button" class="btn btn-outline-secondary" aria-label="Réinitialiser la recherche"
            [disabled]="filter.categories.length === 0 && filter.title === '' && !selectedProgressionPer && !selectedAttentePer" (click)="clearFilter()">
            Réinitialiser la recherche
          </button>
          </div>
        </div>
      </div>
      <div *ngIf="searching" class="text-center">
        <div class="spinner-grow text-secondary" role="status">
          <span class="visually-hidden">Recherche en cours...</span>
        </div>
      </div>
      <div *ngIf="!searching">
        <div class="row py-2" *ngIf="lists && lists.length === 0">
          <div class="col-md-12">
            <div class="alert alert-info" role="alert" *ngIf="!lexique && isFiltered()">
              <span class="bi bi-exclamation-circle-fill"></span> Aucune liste ne correspond à ces critères, <a
                [routerLink]="[]" (click)="clearFilter()" class="alert-link">réinitialiser</a> la recherche pour
              trouver ce que vous cherchez.
            </div>
            <div class="alert alert-info" role="alert" *ngIf="lexique && isFiltered()">
              <span class="bi bi-exclamation-circle-fill"></span> Rien dans le lexique ne correspond à ces critères, <a
                [routerLink]="[]" (click)="clearFilter()" class="alert-link">réinitialiser</a> la recherche pour
              trouver ce que vous cherchez.
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!isFiltered() && rpn">
              <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
              <p>Aucune liste RPN n'est disponible pour le moment.</p>
              <p>Peut être qu'une <a [routerLink]="['/listes/lexique']">liste du lexique RPN</a> pourrait vous intéresser ? Il
                vous sera possible d'y sélectionner des éléments de travail pour en faire rapidement une liste utilisable avec
                des activités iClasse.</p>
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!isFiltered() && lexique">
              <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
              <p>Aucun liste n'est actuellement proposé dans le lexique.</p>
              <p>Vous pouvez éventuellement créer une <a [routerLink]="['/listes/personnelles']">liste personnelle</a>.</p>
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!isFiltered() && private">
              <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
              <p>Vous pouvez créer votre premièrer liste personnelle en cliquant sur le bouton <a [routerLink]="[]"
                  (click)="newList()">ajouter</a> ci-dessous.</p>
              <p>Peut être qu'une <a [routerLink]="['/listes/lexique']">liste du lexique RPN</a> pourrait vous intéresser ? Il
                vous sera possible d'y sélectionner des éléments de travail pour en faire rapidement une liste utilisable avec
                des activités iClasse.</p>
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!isFiltered() && teamShared">
              <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
              <p>
                Demandez à vos collègues de vous inclure dans l'équipe pédagogique d'une liste qu'ils gèrent.
              </p>
            </div>
          </div>
        </div>
        <div class="row page-fade" *ngIf="!loading && lists" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
          (scrolled)="loadMoreItems()">
          <div class="col-lg-4 col-md-6 mb-2" *ngIf="private">
            <nemo-card [title]="'Ajouter'" [icon]="'bi bi-plus-circle-fill'" (clicked)="newList()"></nemo-card>
          </div>
          <div class="col-lg-4 col-md-6 mb-2" *ngFor="let list of lists">
            <nemo-card [title]="list.title" tabindex="-1" [ribbon]="list.ribbon" [icon]="list.icon" [tags]="list.categories"
              [color]="list.color" (clicked)="dummy()"
              [routerLink]="['./',list.id]"
              (categoryclicked)="filterCategory($event)"></nemo-card>
          </div>
        </div>
        <div *ngIf="loading || loadMore"  class="text-center">
          <div class="spinner-grow text-secondary" role="status">
            <span class="visually-hidden">Recherche en cours...</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  
 
  
</div>
