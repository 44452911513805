<nemo-basepage [title]="'Modifier le parcours'">
  <div page-body *ngIf="userForm">
    <form [formGroup]="userForm">
      <div class="row">
        <div class="col-lg-4">
          <div>
            <label class="form-label" for="categories" class="visually-hidden">Apparence</label>
            <div class="logo mb-2">
              <nemo-logo [editable]="true" [color]="list.color" [icon]="list.icon" (editedLogo)="updateLogo($event)">
              </nemo-logo>
            </div>
          </div>
          <div class="form-floating mb-2">
            <input type="text" name="title" id="title" class="form-control form-control-lg" placeholder="Titre"
              formControlName="title" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
              maxlength="150" />
            <label for="title">Titre</label>
          </div>
          <div class="form-floating mb-2" *ngIf="list.subtitle || !isCollapsed ">
            <input type="text" name="subtitle" id="subtitle" class="form-control form-control" placeholder="Sous-titre"
              formControlName="subtitle" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
              maxlength="150" />
            <label for="title">Sous-titre</label>
          </div>
          <div class="form-floating mb-2">
            <input type="text" name="description" id="description" class="form-control" placeholder="Description"
              formControlName="description" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
              maxlength="999" />
              <label for="description">Description</label>
          </div>
        </div>
        <div class="col-lg-8" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
          <div class="card mb-2">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <span class="fw-bold">Général</span>
              </li>
              <li class="list-group-item">
                <div class="d-flex flex-wrap py-1">
                  <div class="flex-grow-1 align-self-center pe-2">
                    <span class="lead">Durée indicative</span>
                  </div>
                  <div class="align-self-center">
                    <div class="input-group">
                      <input type="number" name="duration" id="duration" class="form-control" placeholder="Durée"
                        formControlName="duration" aria-describedby="basic-addon">
                      <span class="input-group-text" id="basic-addon">min</span>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex flex-column align-self-center">
                  <div>
                    <span class="lead me-2">Discipline</span>
                  </div>
                  <div class="flex-grow-1">
                     <nemo-category name="categories" [(selectedCategoryKeys)]="list.categories" (changed)="updateClassification($event)"></nemo-category>
                  </div>
                </div>
              </li>
              <li class="list-group-item" *ngIf="publisher">
                <div class="d-flex flex-column align-self-center">
                  <div>
                    <span class="lead me-2 wsnw">Plan d'étude romand</span>
                  </div>
                  <div class="flex-grow-1">
                    <div class="d-flex flex-column flex-xl-row align-self-center" *ngIf="this.list.planEtudeRomandIds">
                      <div class="flex-grow-1 me-1">
                        <div class="row row-cols-1 row-cols-md-2 g-2">
                          <div class="col" *ngFor="let p of list.planEtudeRomandIds">
                            <div class="card">
                              <div class="card-body">
                                <div class="float-end">
                                  <em class="bi bi-x-lg" (click)="dropPer(p)"></em>
                                </div>
                                <nemo-plan-etude-romand-display  [planEtudeRomandId]="p"></nemo-plan-etude-romand-display>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-2 mt-xl-0">
                        <div class="d-grid gap-2">
                          <button type="button" class="btn btn-sm btn-outline-secondary mt-auto" (click)="addPlanEtudeRomand()"><span class="bi bi-plus-lg"></span> Plan d'étude romand</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card mb-2"  *ngIf="publisher">
            <ul class="list-group list-group-flush mt-1">
              <li class="list-group-item">
                <span class="fw-bold">Options de publication</span>
              </li>
              <li class="list-group-item">
                <div class="d-flex flex-wrap py-1">
                  <div class="flex-grow-1 align-self-center pe-2">
                    <label class="lead form-check-label" for="rpn">Publier dans les parcours RPN ?</label>
                  </div>
                  <div class="align-self-center">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="rpn" [(ngModel)]="list.rpn" (change)="itemUpdated()" [ngModelOptions]="{standalone: true}">
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex flex-wrap py-1">
                  <div class="flex-grow-1 align-self-center pe-2">
                    <label class="lead form-check-label" for="libre">Publier dans les parcours publics ?</label>
                  </div>
                  <div class="align-self-center">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="libre"[(ngModel)]="list.libre" (change)="itemUpdated()" [ngModelOptions]="{standalone: true}">
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex flex-wrap py-1">
                  <div class="flex-grow-1 align-self-center pe-2">
                    <label class="lead form-check-label" for="teacher">Publier dans les parcours enseignant·e·s ?</label>
                  </div>
                  <div class="align-self-center">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="teacher" [(ngModel)]="list.teacher" (change)="itemUpdated()" [ngModelOptions]="{standalone: true}">
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <button type="button" form="userForm" class="btn btn-outline-secondary" tooltip="Retour"
            tooltipPlacement="top" [disabled]="userForm.valid && userForm.dirty"
            [routerLink]="['../']"><span class="bi bi-arrow-left"></span>
            Retour</button>
          <button type="button" form="userForm" class="btn btn-outline-secondary" tooltip="Supprimer"
            tooltipPlacement="top" [disabled]="userForm.valid && userForm.dirty" (click)="deleteList()"><span
              class="bi bi-trash-fill"></span> Supprimer</button>
          <button type="submit" form="userForm" class="btn btn-primary" tooltip="Sauvegarder" tooltipPlacement="top"
            [disabled]="!userForm.valid || !userForm.dirty" (click)="submitForm()">
            <span class="bi bi-save-fill"></span> Sauvegarder</button>
          <button type="button" class="btn btn-link" (click)="collapse.toggle()"  [attr.aria-expanded]="!isCollapsed"
            >{{isCollapsed ? 'Plus de détails':'Moins de détails'}}</button>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-md-12">
        <ul ngbNav #nav="ngbNav" class="nav-tabs nav-fill" [(activeId)]="activeId">
          <li [ngbNavItem]="1">
            <a ngbNavLink><span class="bi bi-bookmarks-fill"></span> Ressources</a>
            <ng-template ngbNavContent>
              <div class="sticked-top">
                <div class="card my-2">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-grow-1 me-2 d-grid gap-2">
                          <button type="button" class="btn btn-outline-secondary" tooltip="Ajouter une ressource" (click)="addItem()"><span class="bi bi-plus-circle-fill"></span> Ajouter une ressource</button>
                      </div>
                      <div class="flex me-2 d-grid gap-2">
                        <button type="button" class="btn btn-outline-secondary" tooltip="Ajouter une ressource" (click)="searchRessource()"><span class="bi bi-search"></span> Rechercher une ressource</button>
                      </div>
                      <nemo-action-menu>
                        <button ngbDropdownItem [disabled]="cartItems && cartItems.length <= 0" (click)="addFromCart()">
                          Ajouter une ressource du panier
                        </button>
                        <button class="dropdown-item" (click)="importFromText()">
                          Importer depuis du texte
                        </button>
                      </nemo-action-menu>
                      <div class="ms-2">
                        <button type="submit" form="userForm" class="btn btn-outline-primary" tooltip="Sauvegarder" tooltipPlacement="top" [disabled]="!userForm.valid || !userForm.dirty" (click)="submitForm()"><span class="bi bi-save-fill"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" [dragula]="'dnditems'" [(dragulaModel)]="list.items">
                <div *ngFor="let item of list.items; let i = index; let last = last" class="col-md-12">
                  <nemo-parcours-item [item]="item" (edit)="editItem(item)" (delete)="deleteItem(item)" (moveup)="moveItemUp(item)" (movedown)="moveItemDown(item)"></nemo-parcours-item>
                  <div class="text-center d-grid gap-2">
                    <button type="button" class="btn btn-outline-secondary btn-sm my-1" tooltip="Ajouter une ressource" (click)="addItem(i + 1)"><span class="bi bi-plus-circle-fill"></span> Ajouter une ressource ici</button>
                  </div>
                </div>
                <p id="end"></p>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2" *ngIf="teacher">
            <a ngbNavLink><span class="bi bi-people-fill"></span> Équipe pédagogique</a>
            <ng-template ngbNavContent>
              <nemo-educational-team-edit [(educationalTeam)]="list.educationalTeam" (educationalTeamChange)="educationalTeamChanged()">
                <button type="submit" form="userForm" class="btn btn-outline-primary" tooltip="Sauvegarder" tooltipPlacement="top" [disabled]="!userForm.valid || !userForm.dirty" (click)="submitForm()"><span class="bi bi-save-fill"></span></button>
              </nemo-educational-team-edit>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
</nemo-basepage>
