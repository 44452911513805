import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { BookletService, ReferenceDataService } from '@app/core/service';
import { Observation, MonitoredStudent, Booklet,
   Criteria, Category, EvaluationScale, Filter, FilterableLabelledObservation, Progression, EvaluationLevel, FilterCategory } from '@app/model';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import * as Chart from 'chart.js';
import { DialogService } from '@app/core/service/dialog.service';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '@app/core/guards/CanComponentDeactivate';
import { ObservationComponent } from '../observation/observation.component';
import { BookletObservationActionsToolbarComponent } from '../booklet-observation-actions-toolbar/booklet-observation-actions-toolbar.component';
import { NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, NgbNavOutlet } from '@ng-bootstrap/ng-bootstrap';
import { TagsTogglerComponent } from '../../../shared/components/tags-toggler/tags-toggler.component';
import { StackedProgressBarComponent } from '../../../shared/components/stacked-progress-bar/stacked-progress-bar.component';
import { CardComponent } from '../../../shared/components/card/card.component';
import { NgIf, NgFor } from '@angular/common';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';


@Component({
    selector: 'nemo-booklet-student',
    templateUrl: './booklet-student.component.html',
    styleUrls: ['./booklet-student.component.scss'],
    standalone: true,
    imports: [BasepageComponent, NgIf, CardComponent, RouterLink, StackedProgressBarComponent, TagsTogglerComponent, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, BookletObservationActionsToolbarComponent, NgFor, ObservationComponent, NgbNavOutlet]
})
// eslint-disable-next-line @angular-eslint/component-class-suffix

export class BookletStudentComponent implements OnInit, CanComponentDeactivate {
  @ViewChild('lineChart', { static: true }) private readonly chartRef;

  @HostListener('window:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        this.saveObservations();
        event.preventDefault();
    }
  }

  chart: any;
  activeId: any;

  public student: MonitoredStudent;
  public booklet: Booklet;
  public filters: Filter[][]=[];
  public filteredCriterias: Criteria[]=[];
  public disciplines: FilterCategory[]=[];
  public annees: FilterCategory[]=[];
  public axesThematiques: Filter[]=[];
  public observations: FilterableLabelledObservation[]=[];
  public filteredObservations: FilterableLabelledObservation[]=[];
  public dirty = false;
  public saving = false;
  public scale: EvaluationScale;
  public progression: Progression;
  public displayAutoEvaluationIcon = false;
  public generalCommentCriteriaIds: number[];


  constructor(public router: Router,
    private readonly service: BookletService,
    private readonly referenceDataService: ReferenceDataService,
    private readonly route: ActivatedRoute,
    private readonly dialogService: DialogService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.service.getObservationsByStudent(params['bookletid'], params['studentid']).subscribe(resp => {
        this.service.get(params['bookletid']).subscribe(booklet => {
          this.scale = this.service.getEvaluationScale(booklet.optionsProfessionnelles);
          this.displayAutoEvaluationIcon = booklet.criterias.filter(c => c.studentEvaluationEnabled).length > 0;
          this.booklet = booklet;
          this.generalCommentCriteriaIds = booklet.criterias.filter(c => c.label === 'Commentaire général').map(c => c.id);
          this.student = booklet.monitoredStudents.find(c => String(c.id) === params['studentid']);
          const tags = [];
          this.filteredCriterias = booklet.criterias;
          this.disciplines = this.referenceDataService.getCategories().filter(c=>booklet.categories.includes(c.key) && c.group=="Discipline").sort((a, b) => a.ord - b.ord).map(d=>{
            return {
              category:d,
              selected:false,
              value:d.value
            }});
          this.annees = this.referenceDataService.getCategories().filter(c=>booklet.categories.includes(c.key) && c.group=="Année").sort((a, b) => a.ord - b.ord).map(d=>{
            return {
              category:d,
              selected:false,
              value:d.value
            }});
          [0, 1, 2, 3, 4, 5, 6, 7].forEach(tidx => {
            const catTags = [].concat([], booklet.criterias
              .map(l => l.tags[tidx])).filter(Boolean).filter(function (elem, index, self) {
              return index === self.indexOf(elem);
            });
            if (catTags.length > 0) {
              tags.push(catTags.map(t => new Filter(t)));
            }
          });
          this.axesThematiques = [...new Set(booklet.criterias.map(c=>c.axesThematiques).filter(c=>c!=null).flat())].map(a=>new Filter(a));
          this.filters = tags;
          this.observations = this.mapObservations(resp.observations);
          this.filteredObservations = this.observations;
          this.initCharts();
        });
      });
    });
  }


private mapObservations(observations: Observation[]): FilterableLabelledObservation[] {
  return this.booklet.criterias.map(c => {
    const o = observations.find(u => u.criteriaId === c.id);
    return new FilterableLabelledObservation({
      id: o ? o.id : 0,
      dirty: false,
      label:  c.label,
      comment:  o ? o.comment :  '',
      state: o && o.state != null ? o.state : 'unset',
      selfComment:  o ? o.selfComment :  '',
      selfState: o && o.selfState != null ? o.selfState : 'unset',
      monitoredStudentId: this.student.id,
      criteriaId: c.id,
      markUrl: o ? o.markUrl : '',
      checked: o ? o.checked : false,
      autoEvaluationEnabled: c.studentEvaluationEnabled
    });
  });
}
  canDeactivate(): Observable<boolean> | boolean {
    // Allow synchronous navigation (`true`) if no crisis or the crisis is unchanged
    if (!this.dirty) {
      return true;
    }
    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    return this.dialogService.confirm('Vous n\'avez pas sauvegardé les modifications, souhaitez-vous continuer sans sauvegarder ?');
  }

  initCharts() {
    this.updateProgression();
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: this.scale.levels.map(l =>
            this.filteredObservations.filter(o => o.state === l.code && this.generalCommentCriteriaIds.indexOf(o.criteriaId) < 0).length),
            backgroundColor: this.scale.levels.map(l => l.color),
            hoverBackgroundColor: this.scale.levels.map(l => l.color)
          }
        ],
        labels: this.scale.levels.map(l => l.label)
      },
      options: {
        cutoutPercentage: 70,
        animation: {
            animateScale: true,
            animateRotate: true
        },
        legend: {
          display: true,
          position: 'right'

      }
    }
    });
  }
  updateChart() {
    this.chart.data.datasets[0].data = this.scale.levels.map(l => this.filteredObservations
      .filter(o => o.state === l.code && this.filteredCriterias.filter(c => this.generalCommentCriteriaIds.indexOf(c.id) < 0)
      .map(q => q.id).indexOf(o.criteriaId) >= 0 ).length
    );
    this.updateProgression();
    this.chart.update();
  }
  updateProgression() {
    const filobs = this.filteredObservations.filter(o => this.generalCommentCriteriaIds.indexOf(o.criteriaId) < 0);
    if (this.booklet.optionsProfessionnelles) {
      this.progression = {
        success: filobs.filter(o => o.state === 'ok').length / filobs.length * 100,
        danger: filobs.filter(o => o.state === 'nok').length / filobs.length * 100,
        info: filobs.filter(o => o.state === 'unrated').length / filobs.length * 100
      };
    } else {
      this.progression = {
        success: filobs.filter(o => o.state === 'ok').length / filobs.length * 100,
        warning: filobs.filter(o => o.state === 'nok').length / filobs.length * 100,
        secondary: filobs.filter(o => o.state === 'unrated').length / filobs.length * 100
      };
    }

  }
  editCriteria(criteria: Criteria) {
    this.router.navigate(['folios/critere/', this.booklet.id, criteria.id]);
  }
  updateObservation(obs: Observation) {
    obs.dirty = true;
    this.dirty = true;
  }
  saveObservations() {
    this.saving = true;
    this.service.saveObservationsForStudent(this.booklet.id, this.student.id, this.observations.filter(o => o.dirty)).subscribe(observations => {
      this.observations = this.mapObservations(observations);
      this.applyCriteriaFiltering();
      this.saving = false;
      this.dirty = false;
      this.updateChart();
    });
  }
  applyCriteriaFiltering() {
    if (this.anyFilterActivated()) {
      const activatedFilter = [];
      this.filters.forEach(cat => {
        if (cat.filter(f => f.selected).length > 0) {
          activatedFilter.push(cat.filter(f => f.selected));
        }
      });
      this.booklet.criterias.forEach(i => {
        i.filtered = activatedFilter.some(cat => cat.every((f: Filter) => i.tags.indexOf(f.value) < 0))
         ||
         (this.disciplines.filter(d => d.selected).length > 0 &&
         this.disciplines.filter(d => d.selected).every(cat =>  i.categories.indexOf(cat.category.key) < 0)
         ||
         (this.annees.filter(d => d.selected).length > 0 &&
         this.annees.filter(d => d.selected).every(cat =>  i.categories?.indexOf(cat.category.key) < 0))
         ||
         (this.axesThematiques.filter(d => d.selected).length > 0 &&
         this.axesThematiques.filter(d => d.selected).every(cat =>  i.axesThematiques?.indexOf(cat.value) < 0)))
         ;
      });
    } else {
      this.booklet.criterias.forEach(i => {
        i.filtered = false;
      });
    }
    this.filteredCriterias = this.booklet.criterias.filter(c => !c.filtered);
    this.filteredObservations = this.observations.filter(o => this.filteredCriterias.map(c => c.id).indexOf(o.criteriaId) >= 0 );
    this.updateChart();
  }
  anyFilterActivated(): boolean {
    return this.filters.filter(cat => cat.filter(f => f.selected).length > 0).length > 0
      ||
      this.disciplines.filter(d => d.selected).length > 0
      ||
      this.annees.filter(d => d.selected).length > 0
      ||
      this.axesThematiques.filter(d => d.selected).length > 0
      ;
  }

  updateAllEvaluation(evaluation: EvaluationLevel) {
    this.filteredObservations.forEach(o => {
      o.state = evaluation.code;
      o.checked = false;
      o.dirty = true;
    });
    this.dirty = true;
  }
  markAll(url: string) {
    this.filteredObservations.forEach(o => {
      o.markUrl = url;
      o.dirty = true;
    });
    this.dirty = true;
  }
  commentAllObservations(comment: string) {
    this.filteredObservations.forEach(o => {
      o.comment = comment;
      o.checked = false;
      o.dirty = true;
    });
    this.dirty = true;
  }

  eraseAllComments() {
    this.filteredObservations.forEach(o => {
      o.comment = '';
      o.checked = false;
      o.dirty = true;
    });
    this.dirty = true;
  }
  eraseAllEvaluations() {
    this.filteredObservations.forEach(o => {
      o.state = null;
      o.checked = false;
      o.dirty = true;
    });
    this.dirty = true;
  }
  eraseAllMarks() {
    this.filteredObservations.forEach(o => {
      o.markUrl = null;
      o.dirty = true;
    });
    this.dirty = true;
  }
  generateReport() {
    this.service.generateStudentReport(this.booklet, this.student, this.observations);
  }
}


