<div *ngIf="filter">
  <div class="row">
    <div class="col-lg-3 d-none d-lg-block">
      <nemo-new-category-selector *ngIf="selectableCategories" #cat [selectableCategoriesKeys]="selectableCategories" [selectedCategoriesKeys]="filter.categories" (selectedCategoriesChange)="filterCategory($event)"></nemo-new-category-selector>
      <div class="d-grid gap-2 mb-2">
        <button type="button" class="btn btn-outline-secondary" aria-label="Réinitialiser la recherche"
          [disabled]="filter.categories.length === 0 && filter.title === ''" (click)="clearFilter()">
          Réinitialiser la recherche
        </button>
      </div>
    </div>
    <div class="col-lg-9">
      <div class="d-flex flex-column flex-md-row align-self-center">
        <div class="flex-grow-1 mt-2 mt-md-0 me-0 me-md-2">
          <div class="d-grid gap-2">
            <div class="input-group">
              <input type="text" class="form-control" (keyup.enter)="search()" [(ngModel)]="filter.title"
                placeholder="Rechercher" aria-label="Rechercher" autocomplete="off" autocorrect="off" autocapitalize="off"
                spellcheck="false" maxlength="255" />
              <button class="btn btn-outline-secondary" (click)="clearTerm()" type="sumbit">
                <span class="bi bi-x-lg"></span>
              </button>
              <button *ngIf="isPublisher() && rpn" class="btn btn-outline-secondary" (click)="exportList()">
                <i class="bi bi-arrow-up-square"></i>
              </button>
              <button class="btn btn-outline-secondary" (click)="search()" type="sumbit">
                <span class="bi bi-search"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="mt-2 mt-md-0 mb-2">
          <div class="d-grid gap-2">
            <button type="button" class="btn btn-outline-secondary" aria-label="Réinitialiser la recherche"
            [disabled]="filter.categories.length === 0 && filter.title === ''" (click)="clearFilter()">
            Réinitialiser la recherche
          </button>
          </div>
        </div>
      </div>
      <div *ngIf="searching" class="text-center">
        <div class="spinner-grow text-secondary" role="status">
          <span class="visually-hidden">Recherche en cours...</span>
        </div>
      </div>
      <div *ngIf="!searching">
        <div class="row py-2" *ngIf="booklets && booklets.length === 0">
          <div class="col-md-12">
            <div class="alert alert-info" role="alert" *ngIf="isFiltered()">
              <span class="bi bi-exclamation-circle-fill"></span> Aucun folio ne correspond à ces critères,
              <a [routerLink]="[]" (click)="initFilter()" class="alert-link">réinitialiser</a>
              la recherche pour trouver ce que vous cherchez.
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!isFiltered() && rpn">
              <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
              <p>
                Aucun modèle n'est disponible pour le moment, merci de repasser plus tard.
              </p>
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!isFiltered() && private">
              <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
              <p>
                Vous pouvez créer votre premier folio en cliquant sur le bouton
                <a [routerLink]="[]" (click)="newBooklet()">ajouter</a>
                ci-dessous.
              </p>
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!isFiltered() && teamShared">
              <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
              <p>
                Demandez à vos collègues de vous inclure dans l'équipe pédagogique d'un folio qu'ils gèrent.
              </p>
            </div>
          </div>
        </div>
        <div class="row page-fade" *ngIf="!loading && booklets" infiniteScroll [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="50" (scrolled)="loadMoreItems()">
          <div class="col-lg-4 col-md-6 mb-2" *ngIf="private">
            <nemo-card [title]="'Ajouter'" [icon]="'bi bi-plus-circle-fill'" (clicked)="newBooklet()"></nemo-card>
          </div>
          <div class="col-lg-4 col-md-6 mb-2" *ngFor="let booklet of booklets">
            <nemo-card [title]="booklet.title" [ribbon]="booklet.ribbon" [icon]="booklet.icon"
              [color]="booklet.color" [progression]="booklet.progression"
              [routerLink]="['./', booklet.id]"
              tabindex="-1"
              (clicked)="bookletClicked()"
              ></nemo-card>
          </div>
        </div>
        <div class="row" *ngIf="loading || loadMore">
          <div class="col-md-12 text-center">
            <div class="spinner-grow text-secondary" role="status">
              <span class="visually-hidden">Recherche en cours...</span>
            </div> 
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
