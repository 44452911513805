import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { BookletService, ReferenceDataService } from '@app/core/service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
// eslint-disable-next-line max-len
import { Observation, Booklet, Criteria, MonitoredStudent, LabelledObservation, Category, Progression, EvaluationScale, EvaluationLevel } from '@app/model';
import * as Chart from 'chart.js';
import { CanComponentDeactivate } from '@app/core/guards/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { DialogService } from '@app/core/service/dialog.service';
import { ObservationComponent } from '../observation/observation.component';
import { BookletObservationActionsToolbarComponent } from '../booklet-observation-actions-toolbar/booklet-observation-actions-toolbar.component';
import { NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, NgbNavOutlet } from '@ng-bootstrap/ng-bootstrap';
import { StackedProgressBarComponent } from '../../../shared/components/stacked-progress-bar/stacked-progress-bar.component';
import { CardComponent } from '../../../shared/components/card/card.component';
import { NgIf, NgFor } from '@angular/common';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';

@Component({
    selector: 'nemo-booklet-criteria',
    templateUrl: './booklet-criteria.component.html',
    styleUrls: ['./booklet-criteria.component.scss'],
    standalone: true,
    imports: [BasepageComponent, NgIf, CardComponent, RouterLink, StackedProgressBarComponent, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, BookletObservationActionsToolbarComponent, NgFor, ObservationComponent, NgbNavOutlet]
})
export class BookletCriteriaComponent implements OnInit, CanComponentDeactivate {
  @ViewChild('lineChart', { static: true }) private readonly chartRef;
  @HostListener('window:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        this.saveObservations();
        event.preventDefault();
    }
  }
  activeId: any;
  chart: any;

  public criteria: Criteria;
  public booklet: Booklet;
  public observations: LabelledObservation[];
  public dirty = false;
  public saving = false;
  categories: Category[];
  public scale: EvaluationScale;
  public progression: Progression;
  public displayAutoEvaluationIcon = false;
  public isGeneralComment: boolean;

  constructor(public router: Router,
    private readonly service: BookletService,
    private readonly referenceDataService: ReferenceDataService,
    private readonly route: ActivatedRoute,
    private readonly dialogService: DialogService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.service.getObservationsByCriteria(params['bookletid'], params['criteriaid']).subscribe(resp => {
        this.service.get(params['bookletid']).subscribe(booklet => {
          this.scale = this.service.getEvaluationScale(booklet.optionsProfessionnelles);
          this.booklet = booklet;
          this.criteria = resp.criteria;
          this.isGeneralComment = this.criteria.label === 'Commentaire général';
          this.displayAutoEvaluationIcon = this.criteria.studentEvaluationEnabled;
          this.categories = this.referenceDataService.getCategories().filter(c=>this.criteria.categories.includes(c.key));
          this.observations = this.mapObservations(resp.observations);
          this.initCharts();
        });
      });
    });
  }

  private mapObservations(observations: Observation[]): LabelledObservation[] {
    return this.booklet.monitoredStudents.map(s => {
      const o = observations.find(u => u.monitoredStudentId === s.id);
      return new LabelledObservation({
        id: o ? o.id : 0,
        dirty: false,
        label:  s.displayName,
        monitoredStudentId: s.id,
        comment:  o ? o.comment :  '',
        state: o && o.state != null ? o.state : 'unset',
        selfComment:  o ? o.selfComment :  '',
        selfState: o && o.selfState != null  ? o.selfState : 'unset',
        criteriaId: this.criteria.id,
        markUrl: o ? o.markUrl : '',
        checked: o ? o.checked : false,
        autoEvaluationEnabled : this.criteria.studentEvaluationEnabled
      });
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    // Allow synchronous navigation (`true`) if no crisis or the crisis is unchanged
    if (!this.dirty) {
      return true;
    }
    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    return this.dialogService.confirm('Vous n\'avez pas sauvegardé les modifications, souhaitez-vous continuer sans sauvegarder ?');
  }

  initCharts() {
    this.updateProgression();
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: this.scale.levels.map(l => this.observations.filter(o => o.state === l.code).length),
            backgroundColor: this.scale.levels.map(l => l.color),
            hoverBackgroundColor: this.scale.levels.map(l => l.color)
          }
        ],
        labels: this.scale.levels.map(l => l.label)
      },
      options: {
        cutoutPercentage: 70,
        animation: {
            animateScale: true,
            animateRotate: true
        },
        legend: {
          display: true,
          position: 'right'

      }
    }
    });
  }

  editStudent(student: MonitoredStudent) {
    this.router.navigate(['folios/eleve/', this.booklet.id, student.id]);
  }
  updateObservation(obs: Observation) {
    obs.dirty = true;
    this.dirty = true;
  }
  saveObservations() {
    this.saving = true
      this.service.saveObservationsForCriteria(this.booklet.id, this.criteria.id, this.observations.filter(o => o.dirty)).subscribe(r => {
        this.observations = this.mapObservations(r);
        this.dirty = false;
        this.saving = false;
        this.updateChart();
      });
  }
  updateChart() {
    if (!this.isGeneralComment) {
      this.chart.data.datasets[0].data = this.scale.levels.map(l => this.observations
        .filter(o => o.state === l.code).length
      );
      this.updateProgression();
      this.chart.update();
    }
  }

  updateProgression() {
    if (!this.isGeneralComment) {
      if (this.booklet.optionsProfessionnelles) {
        this.progression = {
          success: this.observations.filter(o => o.state === 'ok').length / this.observations.length * 100,
          danger: this.observations.filter(o => o.state === 'nok').length / this.observations.length * 100,
          info: this.observations.filter(o => o.state === 'unrated').length / this.observations.length * 100
        };
      } else {
        this.progression = {
          success: this.observations.filter(o => o.state === 'ok').length / this.observations.length * 100,
          warning: this.observations.filter(o => o.state === 'nok').length / this.observations.length * 100,
          secondary: this.observations.filter(o => o.state === 'unrated').length / this.observations.length * 100
        };
      }
    }

  }
  markAll(url: string) {
    this.observations.forEach(o => {
      o.markUrl = url;
      o.dirty = true;
    });
    this.dirty = true;
  }
  updateAllEvaluation(evaluation: EvaluationLevel) {
    this.observations.forEach(o => {
      o.state = evaluation.code;
      o.checked = false;
      o.dirty = true;
    });
    this.dirty = true;
  }

  commentAllObservations(comment: string) {
    this.observations.forEach(o => {
        o.comment = comment;
        o.checked = false;
        o.dirty = true;
      });
    this.dirty = true;
  }

  eraseAllComments() {
    this.observations.forEach(o => {
      if (o.comment && o.comment.length > 0 ) {
        o.comment = '';
        o.checked = false;
        o.dirty = true;
      }
    });
    this.dirty = true;
  }
  eraseAllEvaluations() {
    this.observations.forEach(o => {
      o.state = null;
      o.checked = false;
      o.dirty = true;
    });
    this.dirty = true;
  }
  eraseAllMarks() {
    this.observations.forEach(o => {
      o.markUrl = null;
      o.dirty = true;
    });
    this.dirty = true;
  }
}

