import { Injectable } from '@angular/core';
import {
  ItemList,
  Language,
  Search,
  SearchNatureEnum,
} from '@app/model';
import { Observable } from 'rxjs';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root',
})
export class ListeService {
  private readonly url = '/liste';
  private searchFilter: Search;

  constructor(private readonly http: HttpBaseService) { }

  public getLists(search: Search): Observable<ItemList[]> {
    let apiPath: string;
    switch (search.nature) {
      case SearchNatureEnum.Lexique: {
        apiPath = '/lexique';
        break;
      }
      case SearchNatureEnum.Rpn: {
        apiPath = '/rpn';
        break;
      }
      case SearchNatureEnum.TeamShared: {
        apiPath = '/equipe-pedagogique';
        break;
      }
      case SearchNatureEnum.Private: {
        apiPath = '/personnelle';
        break;
      }
      default: {
        apiPath = '/personnelle';
        break;
      }
    }
    return this.http.get<ItemList[]>(this.url + apiPath, {
      params: {
        text: search.title.trim(),
        categories: search.categories,
        skip: search === null ? '0' : `${search.skip}`
        // onlyDistributed: false,
        // progressionPerIds: search.progressionPerIds,
        // attentePerIds: search.attentePerIds
      },
    });
  }

  public getPersonnalLists(): Observable<ItemList[]> {
    return this.getLists({
      title: '',
      type:'',
      nature: SearchNatureEnum.Private,
      categories: [],
      skip: 0,
      onlyDistributed: false,
      planEtudeRomandId: ''
    });
  }

  public getList(id: string): Observable<ItemList> {
    return this.http.get<ItemList>(`${this.url}/${id}`);
  }

  public getNewList(): Observable<ItemList> {
    return this.http.post<ItemList>(this.url, {
      title: 'Nouvelle liste',
      description: '',
      icon: 'bi bi-card-list',
      color: 'c-grey',
      learnLanguage: 'fr',
      hintLanguage: 'fr',
    });
  }

  public cloneList(list: ItemList): Observable<ItemList> {
    return this.http.post<ItemList>(this.url, list);
  }

  public saveList(list: ItemList): Observable<ItemList> {
    return this.http.put<ItemList>(this.url, list);
  }

  public deleteList(id: number): Observable<ItemList> {
    return this.http.delete<ItemList>(`${this.url}/${id}`);
  }

  public getSecretLink(id: number): Observable<string> {
    return this.http.get<string>(`${this.url}/${id}/secret-link`);
  }

  public translateText(
    srcTxt: string,
    srcLanguage: Language,
    targetLanguage: Language
  ): string {
    return 'traduction';
  }
  public getSearchFilter(): Search {
    if (this.searchFilter) {
      return this.searchFilter;
    } else {
      return {
        nature: SearchNatureEnum.Private,
        title: '',
        type:'',
        categories: [],
        skip: 0,
        onlyDistributed: false,
        planEtudeRomandId: ''
      };
    }
  }
  public setSearchFilter(filter: Search) {
    this.searchFilter = filter;
  }
  public extractListFromText(text: string): ItemList {
    var results = text.match(/("[^"]+"|[^"\s]+)/g);
    var e = results.map(u => u.toLowerCase()).filter((item, i, ar) => ar.indexOf(item) === i).filter(i => i.length > 3).sort();
    return {
      title: 'Liste depuis du texte',
      educationalTeam:[],
      description: '',
      createdBy: '',
      hintLanguage: 'fr',
      learnLanguage: 'fr',
      icon: 'bi bi-card-list',
      color: 'c-grey',  
      canEdit: true,
      lexicon: false,
      rpn: false,
      id: 0,
      items: e.map(i =>{
        return {
          id:0,
          learnTxt: i
        }
      })
    };
  }
}
