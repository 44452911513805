import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { Observable } from 'rxjs';
import {  User } from '@app/model';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {
  private readonly url = '/enseignant';

  constructor(private readonly http: HttpBaseService) {}

  public search(term: string): Observable<User[]> {
    return this.http
      .get<User[]>(`${this.url}/${term}`);
  }
}
