<nemo-basepage [title]="'Administration'">
  <div page-body>
    <div class="row">
      <div class="col">
      </div>
      <div class="col">
        <div class="d-grid gap-2 mb-2">
          <button class="btn btn-outline-primary" type="button" [disabled]="inProcess" (click)="importPerFromCiip()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="inProcess"></span>
            <span *ngIf="!inProcess">Importer le Plan d'étude romand de la CIIP</span>
            <span *ngIf="inProcess">Génération en cours</span>
          </button>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="col">
      </div>
      <div class="col">
        <div class="d-grid gap-2 mb-2">
          <button class="btn btn-outline-primary" type="button" [disabled]="inProcess" (click)="generateJsonFromPer()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="inProcess"></span>
            <span *ngIf="!inProcess">Générer le json à générer sur ChatGPT</span>
            <span *ngIf="inProcess">Génération en cours</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
      </div>
      <div class="col">
        <div class="d-grid gap-2 mb-2">
          <button class="btn btn-outline-primary" type="button" [disabled]="inProcess"
            (click)="generateLearningJsonFromPer()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="inProcess"></span>
            <span *ngIf="!inProcess">Générer le json d'apprentissage de ChatGPT</span>
            <span *ngIf="inProcess">Génération en cours</span>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
      </div>
      <div class="col">
        <div class="d-grid gap-2 mb-2">
          <button class="btn btn-outline-primary" type="button" [disabled]="inProcess" (click)="generatePerExcel()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="inProcess"></span>
            <span *ngIf="!inProcess">Générer l'excel des apprentissage et de leurs critères</span>
            <span *ngIf="inProcess">Génération en cours</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
      </div>
      <div class="col">
        <div class="dropzone text-center border p-3" *ngIf="!uploadInProgress" nemoDnd (fileDropped)="onFileDropped($event[0])">
          <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files[0])" />
          <h1 class="display-2"><em class="bi bi-cloud-arrow-up-fill"></em></h1>
          <h3>Déposer le json des objectifs ici</h3>
          <p>ou</p>
          <h3><label class="form-label" for="filedrop" class="btn btn-primary">Sélectionner</label></h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
      </div>
      <div class="col">
        <div class="d-grid gap-2 mb-2">
          <button class="btn btn-outline-primary" type="button" [disabled]="inProcess" (click)="importFolioTemplate()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="inProcess"></span>
            <span *ngIf="!inProcess">Générer les folios modèles sur la base du Plan d'étude romand</span>
            <span *ngIf="inProcess">Génération en cours</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
      </div>
      <div class="col">
        <div class="d-grid gap-2 mb-2">
          <button class="btn btn-outline-primary" type="button" [disabled]="inProcess" (click)="deleteDeprecatedFolios()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="inProcess"></span>
            <span *ngIf="!inProcess">Supprimer les folios templates inutiles</span>
            <span *ngIf="inProcess">Génération en cours</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
      </div>
      <div class="col">
        <div class="d-grid gap-2 mb-2">
          <button class="btn btn-outline-primary" type="button" [disabled]="inProcess" (click)="bookletMigrateBookletGrantsToOwners()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="inProcess"></span>
            <span *ngIf="!inProcess">Migrer les équipes pédagogiques dans le nouveau système des Owners</span>
            <span *ngIf="inProcess">Génération en cours</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</nemo-basepage>